<script setup>
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
</script>
<script>
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {mapState} from "pinia";
import {useEnvStore} from '@dutypay/store-modules'
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

export default {
  name: "PaymentInformation",
  props: {
    data: {type: Object, required: true},
    mobile: {type: Boolean, default: false}
  },
  computed: {
    isEmpty() {
      return isEmpty;
    },
    computedData() {
      return {
        payment_startTime: get(this.data, 'payment.startTime', null),
        payment_endDueTime: get(this.data, 'payment.endDueTime', null),
        payment_startDueTime: get(this.data, 'payment.startDueTime', null),
        sp_payment_instrument_id: this.displayValues('taxhub.pages.Reports.ReportDetails.payment.instrument.displayValues-', 'sp.payment.instrument.id'),
        _isBankTransfer: (get(this.data, 'sp.payment.instrument.id', undefined) === "e46f7735-d1ae-4aa5-9220-4ece62672bc6"),
        _isSEPADirectDebit: (get(this.data, 'sp.payment.instrument.id', undefined) === "6e191cab-60d6-41ac-aa39-02c470f0e18e"),
        payment_agent_account_owner: get(this.data, 'payment.agent.account.owner', undefined),
        payment_agent_bank: get(this.data, 'payment.agent.bank', undefined),
        sp_payment_agent_bank_country_translation: get(this.data, `sp.payment.agent.bank.country.translations.${this.$i18n.locale}`, undefined),
        payment_agent_iban: get(this.data, 'payment.agent.iban', undefined),
        payment_agent_swiftcode: get(this.data, 'payment.agent.swiftcode', undefined),
        payment_recipient_account_owner: get(this.data, 'payment.recipient.account.owner', undefined),
        payment_recipient_bank: get(this.data, 'payment.recipient.bank', undefined),
        sp_payment_recipient_bank_country_translation: get(this.data, `sp.payment.recipient.bank.country.translations.${this.$i18n.locale}`, undefined),
        payment_recipient_iban: get(this.data, 'payment.recipient.iban', undefined),
        payment_recipient_swiftcode: get(this.data, 'payment.recipient.swiftcode', undefined),
        payment_reference: get(this.data, 'payment.reference', undefined),
        payment_purpose: get(this.data, 'payment.purpose', undefined),
        payment_amount: get(this.data, 'payment.amount', undefined),
        totalPaymentDue: get(this.data, 'totalPaymentDue', undefined),
      };
    },
    currency() {
      if (get(this.data, 'sp.currency.id', undefined) === undefined)
        return '';
      let currencyId = get(this.data, 'sp.currency.id');
      let currencyTka = "taxhub.pages.Reports.ReportDetails.currency.displayValues-" + currencyId;
      if (!this.debug && this.$t(currencyTka) === currencyTka)
        return currencyId;
      return this.$t(currencyTka);
    },
    hidePropertyPaymentAgentAccountOwner() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentAgentBank() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentAgentBankCountry() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentAgentIBAN() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentAgentSWIFTcode() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentEndDueTime() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentStartDueTime() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentInstrument() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && this.computedData.totalPaymentDue <= 0))
          )
      );
    },
    hidePropertyPaymentRecipientAccountOwner() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e")
          )
      );
    },
    hidePropertyPaymentRecipientBank() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e")
          )
      );
    },
    hidePropertyPaymentRecipientIBAN() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e")
          )
      );
    },
    hidePropertyPaymentRecipientSWIFTcode() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e")
          )
      );
    },
    hidePropertyPaymentReference() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      return (
          protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
          protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d" ||
          protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
          (
              (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3" ||
                  protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd" ||
                  protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f" ||
                  protocolType === "638b1868-a4eb-4028-848b-a85e30f85525" ||
                  protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
                  protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
                  protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
              ((paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && this.computedData.totalPaymentDue <= 0) ||
                  paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
                  paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e")
          )
      );
    },
    ...mapState(useEnvStore, ['debug']),
  },
  methods: {
    displayValues(path, key) {
      if (get(this.data, key, undefined) === undefined)
        return undefined;
      if (!this.debug && this.$t(path + get(this.data, key)) === path + get(this.data, key))
        return get(this.data, key);
      return this.$t(path + get(this.data, key));
    },
  }
}
</script>

<template>
  <div>
    <DpReadValue v-if="!hidePropertyPaymentStartDueTime"
                 :padding-right="!mobile"
                 :data-key="t('payment.startDueTime.label')"
                 :data-value="dayjs.utc(computedData.payment_startDueTime).local().format('LL')"></DpReadValue>
    <DpReadValue v-if="!hidePropertyPaymentEndDueTime"
                 :padding-right="!mobile"
                 :data-key="t('payment.endDueTime.label')"
                 :data-value="dayjs.utc(computedData.payment_endDueTime).local().format('LL')"></DpReadValue>
    <DpReadValue v-if="!hidePropertyPaymentInstrument"
                 :padding-right="!mobile"
                 :data-key="t('payment.instrument.label')"
                 :data-value="computedData.sp_payment_instrument_id"></DpReadValue>
    <template v-if="computedData._isSEPADirectDebit">
      <DpReadValue v-if="!hidePropertyPaymentAgentBank"
                   :padding-right="!mobile"
                   :data-key="t('payment.agent.bank.label')"
                   :data-value="computedData.payment_agent_bank"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentAgentBankCountry"
                   :padding-right="!mobile"
                   :data-key="t('payment.agent.bank.country.label')"
                   :data-value="computedData.sp_payment_agent_bank_country_translation"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentAgentAccountOwner"
                   :padding-right="!mobile"
                   :data-key="t('payment.agent.account.owner.label')"
                   :data-value="computedData.payment_agent_account_owner"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentAgentSWIFTcode"
                   :padding-right="!mobile"
                   :data-key="t('payment.agent.swiftcode.label')"
                   :data-value="computedData.payment_agent_swiftcode"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentAgentIBAN"
                   :padding-right="!mobile"
                   :data-key="t('payment.agent.iban.label')"
                   :data-value="computedData.payment_agent_iban"></DpReadValue>
    </template>
    <template v-if="computedData._isBankTransfer">
      <DpReadValue v-if="!hidePropertyPaymentRecipientBank"
                   :padding-right="!mobile"
                   :data-key="t('payment.recipient.bank.label')"
                   :data-value="computedData.payment_recipient_bank"></DpReadValue>
      <DpReadValue v-if="debug"
                   :padding-right="!mobile"
                   :data-key="t('payment.recipient.bank.country.label')"
                   :data-value="computedData.sp_payment_recipient_bank_country_translation"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentRecipientAccountOwner"
                   :padding-right="!mobile"
                   :data-key="t('payment.recipient.account.owner.label')"
                   :data-value="computedData.payment_recipient_account_owner"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentRecipientSWIFTcode"
                   :padding-right="!mobile"
                   :data-key="t('payment.recipient.swiftcode.label')"
                   :data-value="computedData.payment_recipient_swiftcode"></DpReadValue>
      <DpReadValue v-if="!hidePropertyPaymentRecipientIBAN"
                   :padding-right="!mobile"
                   :data-key="t('payment.recipient.iban.label')"
                   :data-value="computedData.payment_recipient_iban"></DpReadValue>
    </template>
    <DpReadValue v-if="!hidePropertyPaymentReference"
                 :padding-right="!mobile" :data-key="t('payment.reference.label')"
                 :data-value="computedData.payment_reference"></DpReadValue>
    <DpReadValue :padding-right="!mobile" :data-key="t('totalPaymentDue.label')">
        {{ $dp.formatCurrency(computedData.totalPaymentDue) }} {{ currency }}
    </DpReadValue>
  </div>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "ar-AE": {
    "payment": {
      "startDueTime": {
        "label": "بداية فترة السداد"
      },
      "instrument": {
        "label": "وسيلة الدفع"
      },
      "recipient": {
        "bank": {
          "label": "معهد المدفوع لأمره",
          "country": {
            "label": "بلد مقر المعهد"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "بلد مقر المعهد"
          },
          "label": "معهد"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "إشارة دفع"
      },
      "endDueTime": {
        "label": "نهاية فترة السداد"
      }
    },
    "totalPaymentDue": {
      "label": "مبلغ الدفع"
    }
  },
  "ar-EG": {
    "payment": {
      "startDueTime": {},
      "instrument": {},
      "recipient": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "swiftcode": {},
        "iban": {}
      },
      "agent": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "iban": {},
        "swiftcode": {}
      },
      "reference": {},
      "endDueTime": {}
    },
    "totalPaymentDue": {}
  },
  "ar-SA": {
    "payment": {
      "startDueTime": {},
      "instrument": {},
      "recipient": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "swiftcode": {},
        "iban": {}
      },
      "agent": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "iban": {},
        "swiftcode": {}
      },
      "reference": {},
      "endDueTime": {}
    },
    "totalPaymentDue": {}
  },
  "de-DE": {
    "payment": {
      "startDueTime": {
        "label": "Beginn der Zahlfrist"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "recipient": {
        "bank": {
          "label": "Institut des Zahlungsempfängers",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Sitzland des Instituts"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Zahlungsreferenz"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      }
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    }
  },
  "de-AT": {
    "payment": {
      "startDueTime": {
        "label": "Beginn der Zahlfrist"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "recipient": {
        "bank": {
          "label": "Institut des Zahlungsempfängers",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Sitzland des Instituts"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Zahlungsreferenz"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      }
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    }
  },
  "de-CH": {
    "payment": {
      "startDueTime": {
        "label": "Beginn der Zahlfrist"
      },
      "instrument": {
        "label": "Zahlungsinstrument"
      },
      "recipient": {
        "bank": {
          "label": "Institut des Zahlungsempfängers",
          "country": {
            "label": "Sitzland des Instituts"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Sitzland des Instituts"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Kontoinhaber"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Zahlungsreferenz"
      },
      "endDueTime": {
        "label": "Ende der Zahlfrist"
      }
    },
    "totalPaymentDue": {
      "label": "Zahlbetrag"
    }
  },
  "en-US": {
    "payment": {
      "startDueTime": {
        "label": "Beginning of the payment period"
      },
      "instrument": {
        "label": "Payment instrument"
      },
      "recipient": {
        "bank": {
          "label": "Payee's Institute",
          "country": {
            "label": "Country of Seat of the Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Country of Seat of the Institut"
          },
          "label": "Institute"
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Payment reference"
      },
      "endDueTime": {
        "label": "End of the payment period"
      }
    },
    "totalPaymentDue": {
      "label": "Payment amount"
    }
  },
  "en-GB": {
    "payment": {
      "startDueTime": {
        "label": "Beginning of the payment period"
      },
      "instrument": {
        "label": "Payment instrument"
      },
      "recipient": {
        "bank": {
          "label": "Payee's Institute",
          "country": {
            "label": "Country of Seat of the Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Country of Seat of the Institut"
          },
          "label": "Institute"
        },
        "account": {
          "owner": {
            "label": "Account owner"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Payment reference"
      },
      "endDueTime": {
        "label": "End of the payment period"
      }
    },
    "totalPaymentDue": {
      "label": "Payment amount"
    }
  },
  "it-IT": {
    "payment": {
      "startDueTime": {
        "label": "Inizio del periodo di pagamento"
      },
      "instrument": {
        "label": "Strumento di pagamento"
      },
      "recipient": {
        "bank": {
          "label": "Istituto del beneficiario",
          "country": {
            "label": "Paese di sede dell'Istituto"
          }
        },
        "account": {
          "owner": {
            "label": "Titolare dell'account"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Paese di sede dell'Istituto"
          },
          "label": "Istituto"
        },
        "account": {
          "owner": {
            "label": "Titolare dell'account"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referenza di pagamento"
      },
      "endDueTime": {
        "label": "Fine del periodo di pagamento"
      }
    },
    "totalPaymentDue": {
      "label": "Importo del pagamento"
    }
  },
  "fr-FR": {
    "payment": {
      "startDueTime": {
        "label": "Début de la période de paiement"
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "recipient": {
        "bank": {
          "label": "Institut du bénéficiaire",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Pays du siège de l'Institut"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Référence de paiement"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      }
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    }
  },
  "es-ES": {
    "payment": {
      "startDueTime": {
        "label": "Inicio del período de pago"
      },
      "instrument": {
        "label": "Instrumento de pago"
      },
      "recipient": {
        "bank": {
          "label": "Instituto del beneficiario",
          "country": {
            "label": "País de sede del Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propietario de la cuenta"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "País de sede del Institut"
          },
          "label": "Instituto"
        },
        "account": {
          "owner": {
            "label": "Propietario de la cuenta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referencia de pago"
      },
      "endDueTime": {
        "label": "Fin del período de pago"
      }
    },
    "totalPaymentDue": {
      "label": "Monto del pago"
    }
  },
  "pl-PL": {
    "payment": {
      "startDueTime": {
        "label": "Początek okresu płatności"
      },
      "instrument": {
        "label": "Metoda płatności"
      },
      "recipient": {
        "bank": {
          "label": "Instytut odbiorcy płatności",
          "country": {
            "label": "Kraj siedziby instytutu"
          }
        },
        "account": {
          "owner": {
            "label": "Właściciel konta"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Kraj siedziby instytutu"
          },
          "label": "Instytut"
        },
        "account": {
          "owner": {
            "label": "Właściciel konta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Tytuł przelewu"
      },
      "endDueTime": {
        "label": "Koniec terminu płatności"
      }
    },
    "totalPaymentDue": {
      "label": "Wysokość należności"
    }
  },
  "nl-NL": {
    "payment": {
      "startDueTime": {
        "label": "Begin van de betalingsperiode"
      },
      "instrument": {
        "label": "Betaalinstrument"
      },
      "recipient": {
        "bank": {
          "label": "Het instituut van de begunstigde",
          "country": {
            "label": "Land van zetel van het Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Accounteigenaar"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Land van zetel van het Institut"
          },
          "label": "Instituut"
        },
        "account": {
          "owner": {
            "label": "Accounteigenaar"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Betalingskenmerk"
      },
      "endDueTime": {
        "label": "Einde van de betalingsperiode"
      }
    },
    "totalPaymentDue": {
      "label": "Te betalen bedrag"
    }
  },
  "bg-BG": {
    "payment": {
      "startDueTime": {
        "label": "Начало на периода на плащане"
      },
      "instrument": {
        "label": "Платежно нареждане"
      },
      "recipient": {
        "bank": {
          "label": "Институтът на бенефициента",
          "country": {
            "label": "Държава на седалището на Института"
          }
        },
        "account": {
          "owner": {
            "label": "Cобственик на акаунт"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Държава на седалището на Института"
          },
          "label": "Институт"
        },
        "account": {
          "owner": {
            "label": "Cобственик на акаунт"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Справка за плащане"
      },
      "endDueTime": {
        "label": "Срок за плащане на ДДС"
      }
    },
    "totalPaymentDue": {
      "label": "Сума за плащане"
    }
  },
  "bs-BA": {
    "payment": {
      "startDueTime": {
        "label": "Početak perioda plaćanja"
      },
      "instrument": {
        "label": "Instrument plaćanja"
      },
      "recipient": {
        "bank": {
          "label": "Institut primatelja",
          "country": {
            "label": "Zemlja sjedišta Instituta"
          }
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Zemlja sjedišta Instituta"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referenca o uplati"
      },
      "endDueTime": {
        "label": "Kraj perioda plaćanja"
      }
    },
    "totalPaymentDue": {
      "label": "Iznos plaćanja"
    }
  },
  "da-DK": {
    "payment": {
      "startDueTime": {
        "label": "Begyndelsen af betalingsperioden"
      },
      "instrument": {
        "label": "Betalingsinstrument"
      },
      "recipient": {
        "bank": {
          "label": "Betalingsmodtagerens institut",
          "country": {
            "label": "Institut for sæde"
          }
        },
        "account": {
          "owner": {
            "label": "Kontoindehaver"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Institut for sæde"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Kontoindehaver"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Betalings reference"
      },
      "endDueTime": {
        "label": "Betalingsperiodens slutning"
      }
    },
    "totalPaymentDue": {
      "label": "Betalingsbeløb"
    }
  },
  "et-EE": {
    "payment": {
      "startDueTime": {
        "label": "Makseperioodi algus"
      },
      "instrument": {
        "label": "Maksevahend"
      },
      "recipient": {
        "bank": {
          "label": "Saaja instituut",
          "country": {
            "label": "Instituudi asukohariik"
          }
        },
        "account": {
          "owner": {
            "label": "Konto omanik"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Instituudi asukohariik"
          },
          "label": "Instituut"
        },
        "account": {
          "owner": {
            "label": "Konto omanik"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Makseviide"
      },
      "endDueTime": {
        "label": "Makseperioodi lõpp"
      }
    },
    "totalPaymentDue": {
      "label": "Makse summa"
    }
  },
  "fi-FI": {
    "payment": {
      "startDueTime": {
        "label": "Maksuajan alku"
      },
      "instrument": {
        "label": "Maksutapa"
      },
      "recipient": {
        "bank": {
          "label": "Maksunsaajan instituutti",
          "country": {
            "label": "Instituutin kotimaa"
          }
        },
        "account": {
          "owner": {
            "label": "Tilin omistaja"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Instituutin kotimaa"
          },
          "label": "Instituutti"
        },
        "account": {
          "owner": {
            "label": "Tilin omistaja"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Maksuviite"
      },
      "endDueTime": {
        "label": "Maksuajan päättyminen"
      }
    },
    "totalPaymentDue": {
      "label": "Maksun määrä"
    }
  },
  "el-GR": {
    "payment": {
      "startDueTime": {
        "label": "Έναρξη της περιόδου πληρωμής"
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      },
      "recipient": {
        "bank": {
          "label": "Ινστιτούτο δικαιούχου πληρωμής",
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          }
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          },
          "label": "Ινστιτούτο"
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Αναφορά πληρωμής"
      },
      "endDueTime": {
        "label": "Λήξη της περιόδου πληρωμής"
      }
    },
    "totalPaymentDue": {
      "label": "Ποσό ΠΛΗΡΩΜΗΣ"
    }
  },
  "he-IL": {
    "payment": {
      "startDueTime": {
        "label": "תחילת תקופת התשלום"
      },
      "instrument": {
        "label": "מכשיר תשלום"
      },
      "recipient": {
        "bank": {
          "label": "מכון מקבל התשלום",
          "country": {
            "label": "ארץ מושב המכון"
          }
        },
        "account": {
          "owner": {
            "label": "בעל החשבון"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "ארץ מושב המכון"
          },
          "label": "מכון"
        },
        "account": {
          "owner": {
            "label": "בעל החשבון"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "הפניה לתשלום"
      },
      "endDueTime": {
        "label": "סוף תקופת התשלום"
      }
    },
    "totalPaymentDue": {
      "label": "סכום לתשלום"
    }
  },
  "ga-IE": {
    "payment": {
      "startDueTime": {
        "label": "Tús na tréimhse íocaíochta"
      },
      "instrument": {
        "label": "Lonstraim íocaíochta"
      },
      "recipient": {
        "bank": {
          "label": "Institiúid an íocaí",
          "country": {
            "label": "Tír Suíochán an Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Úinéir an chuntais"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Tír Suíochán an Institut"
          },
          "label": "Institiúid"
        },
        "account": {
          "owner": {
            "label": "Úinéir an chuntais"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Tagairt íocaíochta"
      },
      "endDueTime": {
        "label": "Deireadh na tréimhse íocaíochta"
      }
    },
    "totalPaymentDue": {
      "label": "Méid íocaíochta"
    }
  },
  "ja-JP": {
    "payment": {
      "startDueTime": {
        "label": "支払い期間の開始"
      },
      "instrument": {
        "label": "支払い方法"
      },
      "recipient": {
        "bank": {
          "label": "受取人の研究所",
          "country": {
            "label": "研究所の座席の国"
          }
        },
        "account": {
          "owner": {
            "label": "アカウントの所有者"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "研究所の座席の国"
          },
          "label": "研究所"
        },
        "account": {
          "owner": {
            "label": "アカウントの所有者"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "支払いリファレンス"
      },
      "endDueTime": {
        "label": "支払い期間の終了"
      }
    },
    "totalPaymentDue": {
      "label": "支払金額"
    }
  },
  "hr-HR": {
    "payment": {
      "startDueTime": {
        "label": "Početak razdoblja plaćanja"
      },
      "instrument": {
        "label": "Instrument plaćanja"
      },
      "recipient": {
        "bank": {
          "label": "Institut primatelja",
          "country": {
            "label": "Zemlja sjedišta Instituta"
          }
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Zemlja sjedišta Instituta"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Vlasnik računa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referenca o plaćanju"
      },
      "endDueTime": {
        "label": "Kraj razdoblja plaćanja"
      }
    },
    "totalPaymentDue": {
      "label": "Iznos plaćanja"
    }
  },
  "lv-LV": {
    "payment": {
      "startDueTime": {
        "label": "Maksājuma perioda sākums"
      },
      "instrument": {
        "label": "Maksāšanas līdzeklis"
      },
      "recipient": {
        "bank": {
          "label": "Maksājuma saņēmēja institūts",
          "country": {
            "label": "Institūta galvenā mītnes valsts"
          }
        },
        "account": {
          "owner": {
            "label": "Konta īpašnieks"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Institūta galvenā mītnes valsts"
          },
          "label": "Institūts"
        },
        "account": {
          "owner": {
            "label": "Konta īpašnieks"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Maksājuma atsauce"
      },
      "endDueTime": {
        "label": "Maksājuma termiņa beigas"
      }
    },
    "totalPaymentDue": {
      "label": "Maksājuma summa"
    }
  },
  "lt-LT": {
    "payment": {
      "startDueTime": {
        "label": "Mokėjimo laikotarpio pradžia"
      },
      "instrument": {
        "label": "Mokėjimo priemonė"
      },
      "recipient": {
        "bank": {
          "label": "Gavėjo institutas",
          "country": {
            "label": "Instituto buveinės šalis"
          }
        },
        "account": {
          "owner": {
            "label": "Paskyros savininkas"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Instituto buveinės šalis"
          },
          "label": "Institutas"
        },
        "account": {
          "owner": {
            "label": "Paskyros savininkas"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Mokėjimo tvarka"
      },
      "endDueTime": {
        "label": "Mokėjimo laikotarpio pabaiga"
      }
    },
    "totalPaymentDue": {
      "label": "Mokėjimo suma"
    }
  },
  "pt-PT": {
    "payment": {
      "startDueTime": {
        "label": "Início do período de pagamento"
      },
      "instrument": {
        "label": "Instrumento de pagamento"
      },
      "recipient": {
        "bank": {
          "label": "Instituto do beneficiário",
          "country": {
            "label": "País de Sede do Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Dono da conta"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "País de Sede do Institut"
          },
          "label": "Instituto"
        },
        "account": {
          "owner": {
            "label": "Dono da conta"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referência de pagamento"
      },
      "endDueTime": {
        "label": "Fim do período de pagamento"
      }
    },
    "totalPaymentDue": {
      "label": "Valor do pagamento"
    }
  },
  "ro-RO": {
    "payment": {
      "startDueTime": {
        "label": "Începutul perioadei de plată"
      },
      "instrument": {
        "label": "Instrument de plată"
      },
      "recipient": {
        "bank": {
          "label": "Institutul beneficiarului",
          "country": {
            "label": "Țara de reședință a Institutului"
          }
        },
        "account": {
          "owner": {
            "label": "Proprietarul contului"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Țara de reședință a Institutului"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Proprietarul contului"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referință de plată"
      },
      "endDueTime": {
        "label": "Sfârșitul perioadei de plată"
      }
    },
    "totalPaymentDue": {
      "label": "Suma de plată"
    }
  },
  "ru-RU": {
    "payment": {
      "startDueTime": {
        "label": "Начало платежного периода"
      },
      "instrument": {
        "label": "Платежный инструмент"
      },
      "recipient": {
        "bank": {
          "label": "Институт получателя платежей",
          "country": {
            "label": "Страна местонахождения института"
          }
        },
        "account": {
          "owner": {
            "label": "владелец счета"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Страна местонахождения института"
          },
          "label": "Институт"
        },
        "account": {
          "owner": {
            "label": "владелец счета"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Ссылка на платеж"
      },
      "endDueTime": {
        "label": "Конец платежного периода"
      }
    },
    "totalPaymentDue": {
      "label": "Сумма к оплате"
    }
  },
  "sv-SE": {
    "payment": {
      "startDueTime": {
        "label": "Början på betalningsperioden"
      },
      "instrument": {
        "label": "Betalningsinstrument"
      },
      "recipient": {
        "bank": {
          "label": "Betalningsmottagarens institut",
          "country": {
            "label": "Institutets säte"
          }
        },
        "account": {
          "owner": {
            "label": "Konto ägare"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Institutets säte"
          },
          "label": "Inleda"
        },
        "account": {
          "owner": {
            "label": "Konto ägare"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Betalningsreferens"
      },
      "endDueTime": {
        "label": "Betalningsperiodens slut"
      }
    },
    "totalPaymentDue": {
      "label": "Betalningsbelopp"
    }
  },
  "sk-SK": {
    "payment": {
      "startDueTime": {
        "label": "Začiatok platobného obdobia"
      },
      "instrument": {
        "label": "Platobný nástroj"
      },
      "recipient": {
        "bank": {
          "label": "Inštitút príjemcu platby",
          "country": {
            "label": "Krajina sídla inštitútu"
          }
        },
        "account": {
          "owner": {
            "label": "Vlastník účtu"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Krajina sídla inštitútu"
          },
          "label": "Ústav"
        },
        "account": {
          "owner": {
            "label": "Vlastník účtu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Označenie platby"
      },
      "endDueTime": {
        "label": "Koniec platobného obdobia"
      }
    },
    "totalPaymentDue": {
      "label": "Čiastka na úhradu"
    }
  },
  "cs-CZ": {
    "payment": {
      "startDueTime": {
        "label": "Začátek platebního období"
      },
      "instrument": {
        "label": "Platební nástroj"
      },
      "recipient": {
        "bank": {
          "label": "Institut příjemce platby",
          "country": {
            "label": "Země sídla institutu"
          }
        },
        "account": {
          "owner": {
            "label": "Majitel účtu"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Země sídla institutu"
          },
          "label": "Ústav"
        },
        "account": {
          "owner": {
            "label": "Majitel účtu"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Odkaz na platbu"
      },
      "endDueTime": {
        "label": "Konec platebního období"
      }
    },
    "totalPaymentDue": {
      "label": "Částka k úhradě"
    }
  },
  "tr-TR": {
    "payment": {
      "startDueTime": {
        "label": "Ödeme döneminin başlangıcı"
      },
      "instrument": {
        "label": "Ödeme aracı"
      },
      "recipient": {
        "bank": {
          "label": "Alacaklı enstitüsü",
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          }
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          },
          "label": "Enstitü"
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Ödeme referansı"
      },
      "endDueTime": {
        "label": "Ödeme süresinin sonu"
      }
    },
    "totalPaymentDue": {
      "label": "Ödeme miktarı"
    }
  },
  "hu-HU": {
    "payment": {
      "startDueTime": {
        "label": "A fizetési időszak kezdete"
      },
      "instrument": {
        "label": "Fizetés módja"
      },
      "recipient": {
        "bank": {
          "label": "Kedvezményezett intézmény",
          "country": {
            "label": "Az intézet székhelye"
          }
        },
        "account": {
          "owner": {
            "label": "Számlatulajdonos"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Az intézet székhelye"
          },
          "label": "Intézet"
        },
        "account": {
          "owner": {
            "label": "Számlatulajdonos"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Fizetési referencia"
      },
      "endDueTime": {
        "label": "A fizetési időszak vége"
      }
    },
    "totalPaymentDue": {
      "label": "Végösszeg"
    }
  },
  "fr-BE": {
    "payment": {
      "startDueTime": {
        "label": "Début de la période de paiement"
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "recipient": {
        "bank": {
          "label": "Institut du bénéficiaire",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Pays du siège de l'Institut"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Référence de paiement"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      }
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    }
  },
  "fr-LU": {
    "payment": {
      "startDueTime": {
        "label": "Début de la période de paiement"
      },
      "instrument": {
        "label": "Instrument de paiement"
      },
      "recipient": {
        "bank": {
          "label": "Institut du bénéficiaire",
          "country": {
            "label": "Pays du siège de l'Institut"
          }
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Pays du siège de l'Institut"
          },
          "label": "Institut"
        },
        "account": {
          "owner": {
            "label": "Propriétaire du compte"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Référence de paiement"
      },
      "endDueTime": {
        "label": "Fin de la période de paiement"
      }
    },
    "totalPaymentDue": {
      "label": "Montant du paiement"
    }
  },
  "mt-MT": {
    "payment": {
      "startDueTime": {
        "label": "Bidu tal-perjodu tal-ħlas"
      },
      "instrument": {
        "label": "Strument tal-ħlas"
      },
      "recipient": {
        "bank": {
          "label": "L-istitut ta 'Payee",
          "country": {
            "label": "Pajjiż tas-Sede tal-Istitut"
          }
        },
        "account": {
          "owner": {
            "label": "sid tal-kont"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Pajjiż tas-Sede tal-Istitut"
          },
          "label": "Istitut"
        },
        "account": {
          "owner": {
            "label": "sid tal-kont"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Referenza tal-ħlas"
      },
      "endDueTime": {
        "label": "Tmiem il-perjodu tal-ħlas"
      }
    },
    "totalPaymentDue": {
      "label": "Ammont tal-ħlas"
    }
  },
  "sl-SI": {
    "payment": {
      "startDueTime": {
        "label": "Začetek plačilnega obdobja"
      },
      "instrument": {
        "label": "Plačilni instrument"
      },
      "recipient": {
        "bank": {
          "label": "Inštitut prejemnika plačila",
          "country": {
            "label": "Država sedeža Inštituta"
          }
        },
        "account": {
          "owner": {
            "label": "Lastnik računa"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Država sedeža Inštituta"
          },
          "label": "Inštitut"
        },
        "account": {
          "owner": {
            "label": "Lastnik računa"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Sklic na plačilo"
      },
      "endDueTime": {
        "label": "Konec plačilnega obdobja"
      }
    },
    "totalPaymentDue": {
      "label": "Znesek plačila"
    }
  },
  "el-CY": {
    "payment": {
      "startDueTime": {
        "label": "Έναρξη της περιόδου πληρωμής"
      },
      "instrument": {
        "label": "Μέσο πληρωμής"
      },
      "recipient": {
        "bank": {
          "label": "Ινστιτούτο δικαιούχου πληρωμής",
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          }
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Χώρα έδρας του Ινστιτούτου"
          },
          "label": "Ινστιτούτο"
        },
        "account": {
          "owner": {
            "label": "ιδιοκτήτης λογαριασμού"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Αναφορά πληρωμής"
      },
      "endDueTime": {
        "label": "Λήξη της περιόδου πληρωμής"
      }
    },
    "totalPaymentDue": {
      "label": "Ποσό ΠΛΗΡΩΜΗΣ"
    }
  },
  "tr-CY": {
    "payment": {
      "startDueTime": {
        "label": "Ödeme döneminin başlangıcı"
      },
      "instrument": {
        "label": "Ödeme aracı"
      },
      "recipient": {
        "bank": {
          "label": "Alacaklı enstitüsü",
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          }
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        },
        "swiftcode": {
          "label": "BIC"
        },
        "iban": {
          "label": "IBAN"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "Enstitünün Bulunduğu Ülke"
          },
          "label": "Enstitü"
        },
        "account": {
          "owner": {
            "label": "Hesap sahibi"
          }
        },
        "iban": {
          "label": "IBAN"
        },
        "swiftcode": {
          "label": "BIC"
        }
      },
      "reference": {
        "label": "Ödeme referansı"
      },
      "endDueTime": {
        "label": "Ödeme süresinin sonu"
      }
    },
    "totalPaymentDue": {
      "label": "Ödeme miktarı"
    }
  },
  "uk-UA": {
    "payment": {
      "startDueTime": {},
      "instrument": {},
      "recipient": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "swiftcode": {},
        "iban": {}
      },
      "agent": {
        "bank": {
          "country": {}
        },
        "account": {
          "owner": {}
        },
        "iban": {},
        "swiftcode": {}
      },
      "reference": {},
      "endDueTime": {}
    },
    "totalPaymentDue": {}
  },
  "zh-CN": {
    "payment": {
      "startDueTime": {
        "label": "付款期开始"
      },
      "instrument": {
        "label": "付款方式"
      },
      "recipient": {
        "bank": {
          "label": "收款人学院",
          "country": {
            "label": "研究所所在地的国家"
          }
        },
        "account": {
          "owner": {
            "label": "帐户所有者"
          }
        },
        "swiftcode": {
          "label": "工商银行"
        },
        "iban": {
          "label": "伊班"
        }
      },
      "agent": {
        "bank": {
          "country": {
            "label": "研究所所在地的国家"
          },
          "label": "研究所"
        },
        "account": {
          "owner": {
            "label": "帐户所有者"
          }
        },
        "iban": {
          "label": "伊班"
        },
        "swiftcode": {
          "label": "工商银行"
        }
      },
      "reference": {
        "label": "付款凭据"
      },
      "endDueTime": {
        "label": "付款期结束"
      }
    },
    "totalPaymentDue": {
      "label": "支付金额"
    }
  }
}
</i18n>
