<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
import dayjs from 'dayjs';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
</script>
<script>
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import {mapState} from "pinia";
import { useEnvStore } from '@dutypay/store-modules'

export default {
  name: "PaymentDetails",
  props: {
    data: {type: Object, required: true},
    mobile: {type: Boolean, default: false}
  },
  computed: {
    isEmpty() {
      return isEmpty;
    },
    computedData() {
      return {
        payment_startTime: get(this.data, 'payment.startTime', null),
        payment_purpose: get(this.data, 'payment.purpose', undefined),
        payment_amount: get(this.data, 'payment.amount', undefined),
      };
    },
    currency() {
      if (get(this.data, 'sp.currency.id', undefined) === undefined)
        return '';
      let currencyId = get(this.data, 'sp.currency.id');
      let currencyTka = "taxhub.pages.Reports.ReportDetails.currency.displayValues-" + currencyId;
      if (!this.debug && this.$t(currencyTka) === currencyTka)
        return currencyId;
      return this.$t(currencyTka);
    },
    hidePropertyPaymentAmount() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      let totalPaymentDue = get(this.data, 'totalPaymentDue', undefined);
      return (
        protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
        ((protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
            protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d") &&
            totalPaymentDue <= 0) ||
        (
          (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3"||
              protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd"||
              protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f"||
              protocolType === "638b1868-a4eb-4028-848b-a85e30f85525"||
              protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
              protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
              protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
          (paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
          (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && totalPaymentDue <= 0)  ||
          (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && totalPaymentDue <= 0))
        )
      );
    },
    hidePropertyPaymentStartTime() {
      let protocolType = get(this.data, 'sp.report.type.id', undefined);
      let paymentInstrument = get(this.data, 'sp.payment.instrument.id', undefined);
      let totalPaymentDue = get(this.data, 'totalPaymentDue', undefined);
      return (
        protocolType === "4a852410-28b8-4404-b7ee-042df395467a" ||
        ((protocolType === "0d17d135-2598-41fc-b607-83579de951f0" ||
            protocolType === "a91476a5-e8fb-4996-acff-676b51cba03d") &&
            totalPaymentDue <= 0) ||
        (
          (protocolType === "5a249a2e-b2b9-4ad3-a4b1-0d111b0e10c3"||
              protocolType === "94fd6ccb-45b9-48e7-a551-c801895a08bd"||
              protocolType === "ebb86a22-4b8c-46c0-9271-4c4c0f971f2f"||
              protocolType === "638b1868-a4eb-4028-848b-a85e30f85525"||
              protocolType === "fe4c4ee1-2546-4b24-aebe-848f6a5968fb" ||
              protocolType === "d9a18fc3-5dfc-41e1-84de-2c945b109b62" ||
              protocolType === "4f003f6e-a3d0-4fa5-b4e1-6103da25100d") &&
          (paymentInstrument === "43e794a4-4f46-4cc6-86e6-5d280e723bca" ||
          (paymentInstrument === "6e191cab-60d6-41ac-aa39-02c470f0e18e" && totalPaymentDue <= 0) ||
          (paymentInstrument === "e46f7735-d1ae-4aa5-9220-4ece62672bc6" && totalPaymentDue <= 0))
        )
      );
    },
    ...mapState(useEnvStore, ['debug']),
  },
  methods: {
    displayValues(path, key) {
      if (get(this.data, key, undefined) === undefined)
        return undefined;
      if (!this.debug && this.$t(path + get(this.data, key)) === path + get(this.data, key))
        return get(this.data, key);
      return this.$t(path + get(this.data, key));
    },
  }
}
</script>

<template>
  <div>
    <DpReadValue v-if="!hidePropertyPaymentStartTime && computedData.payment_startTime !== null" :padding-right="!mobile"
                 :data-key="t('payment.startTime.label')"
                 :data-value="dayjs.utc(computedData.payment_startTime).local().format('LLL')"></DpReadValue>
    <DpReadValue v-if="debug" :padding-right="!mobile" :data-key="t('payment.purpose.label')"
                 :data-value="computedData.payment_purpose"></DpReadValue>
    <DpReadValue v-if="!hidePropertyPaymentAmount && debug" :padding-right="!mobile" :data-key="t('payment.amount.label')"
                 :data-value="`${$dp.formatCurrency(computedData.payment_amount)} ${currency}`"></DpReadValue>
  </div>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "ar-AE": {
    "payment": {
      "amount": {
        "label": "المبلغ المدفوع"
      },
      "purpose": {
        "label": "إستعمال"
      },
      "startTime": {
        "label": "وقت السداد"
      }
    }
  },
  "ar-EG": {
    "payment": {
      "amount": {},
      "purpose": {},
      "startTime": {}
    }
  },
  "ar-SA": {
    "payment": {
      "amount": {},
      "purpose": {},
      "startTime": {}
    }
  },
  "de-DE": {
    "payment": {
      "amount": {
        "label": "Bezahlter Betrag"
      },
      "purpose": {
        "label": "Verwendungszweck"
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    }
  },
  "de-AT": {
    "payment": {
      "amount": {
        "label": "Bezahlter Betrag"
      },
      "purpose": {
        "label": "Verwendungszweck"
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    }
  },
  "de-CH": {
    "payment": {
      "amount": {
        "label": "Bezahlter Betrag"
      },
      "purpose": {
        "label": "Verwendungszweck"
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    }
  },
  "en-US": {
    "payment": {
      "amount": {
        "label": "Amount Paid"
      },
      "purpose": {
        "label": "Usage"
      },
      "startTime": {
        "label": "Transfer time"
      }
    }
  },
  "en-GB": {
    "payment": {
      "amount": {
        "label": "Amount Paid"
      },
      "purpose": {
        "label": "Usage"
      },
      "startTime": {
        "label": "Transfer time"
      }
    }
  },
  "it-IT": {
    "payment": {
      "amount": {
        "label": "Importo pagato"
      },
      "purpose": {
        "label": "Utilizzo"
      },
      "startTime": {
        "label": "Tempo di trasferimento"
      }
    }
  },
  "fr-FR": {
    "payment": {
      "amount": {
        "label": "Le montant payé"
      },
      "purpose": {
        "label": "Usage"
      },
      "startTime": {
        "label": "Temps de transfert"
      }
    }
  },
  "es-ES": {
    "payment": {
      "amount": {
        "label": "Cantidad pagada"
      },
      "purpose": {
        "label": "Uso"
      },
      "startTime": {
        "label": "Tiempo de transferencia"
      }
    }
  },
  "pl-PL": {
    "payment": {
      "amount": {
        "label": "Opłata zapłacona"
      },
      "purpose": {
        "label": "Stosowanie"
      },
      "startTime": {
        "label": "Data płatności"
      }
    }
  },
  "nl-NL": {
    "payment": {
      "amount": {
        "label": "Betaald bedrag"
      },
      "purpose": {
        "label": "Gebruik"
      },
      "startTime": {
        "label": "Tijdstip van betaling"
      }
    }
  },
  "bg-BG": {
    "payment": {
      "amount": {
        "label": "Платено количество"
      },
      "purpose": {
        "label": "Употреба"
      },
      "startTime": {
        "label": "Време на плащане"
      }
    }
  },
  "bs-BA": {
    "payment": {
      "amount": {
        "label": "Iznos plaćen"
      },
      "purpose": {
        "label": "Upotreba"
      },
      "startTime": {
        "label": "Vrijeme plaćanja"
      }
    }
  },
  "da-DK": {
    "payment": {
      "amount": {
        "label": "Betalt beløb"
      },
      "purpose": {
        "label": "Anvendelse"
      },
      "startTime": {
        "label": "Tidspunkt for betaling"
      }
    }
  },
  "et-EE": {
    "payment": {
      "amount": {
        "label": "Makstud summa"
      },
      "purpose": {
        "label": "Kasutamine"
      },
      "startTime": {
        "label": "Makse aeg"
      }
    }
  },
  "fi-FI": {
    "payment": {
      "amount": {
        "label": "Maksettu summa"
      },
      "purpose": {
        "label": "Käyttö"
      },
      "startTime": {
        "label": "Maksuaika"
      }
    }
  },
  "el-GR": {
    "payment": {
      "amount": {
        "label": "Ποσό που καταβάλλεται"
      },
      "purpose": {
        "label": "Χρήση"
      },
      "startTime": {
        "label": "Ώρα πληρωμής"
      }
    }
  },
  "he-IL": {
    "payment": {
      "amount": {
        "label": "סכום ששולם"
      },
      "purpose": {
        "label": "נוֹהָג"
      },
      "startTime": {
        "label": "זמן התשלום"
      }
    }
  },
  "ga-IE": {
    "payment": {
      "amount": {
        "label": "Méid a d'íoc"
      },
      "purpose": {
        "label": "Úsáid"
      },
      "startTime": {
        "label": "Am na híocaíochta"
      }
    }
  },
  "ja-JP": {
    "payment": {
      "amount": {
        "label": "払込金額"
      },
      "purpose": {
        "label": "使用法"
      },
      "startTime": {
        "label": "お支払い時期"
      }
    }
  },
  "hr-HR": {
    "payment": {
      "amount": {
        "label": "Uplaćeni iznos"
      },
      "purpose": {
        "label": "Upotreba"
      },
      "startTime": {
        "label": "Vrijeme plaćanja"
      }
    }
  },
  "lv-LV": {
    "payment": {
      "amount": {
        "label": "Samaksātā summa"
      },
      "purpose": {
        "label": "Lietošana"
      },
      "startTime": {
        "label": "Apmaksas laiks"
      }
    }
  },
  "lt-LT": {
    "payment": {
      "amount": {
        "label": "Sumokėta suma"
      },
      "purpose": {
        "label": "Naudojimas"
      },
      "startTime": {
        "label": "Mokėjimo laikas"
      }
    }
  },
  "pt-PT": {
    "payment": {
      "amount": {
        "label": "Quantia paga"
      },
      "purpose": {
        "label": "Uso"
      },
      "startTime": {
        "label": "Hora do pagamento"
      }
    }
  },
  "ro-RO": {
    "payment": {
      "amount": {
        "label": "Suma plătită"
      },
      "purpose": {
        "label": "Utilizare"
      },
      "startTime": {
        "label": "Ora de plată"
      }
    }
  },
  "ru-RU": {
    "payment": {
      "amount": {
        "label": "Оплаченная сумма"
      },
      "purpose": {
        "label": "использование"
      },
      "startTime": {
        "label": "Время оплаты"
      }
    }
  },
  "sv-SE": {
    "payment": {
      "amount": {
        "label": "Betalt belopp"
      },
      "purpose": {
        "label": "Användande"
      },
      "startTime": {
        "label": "Betalningstid"
      }
    }
  },
  "sk-SK": {
    "payment": {
      "amount": {
        "label": "Čiastka vyplatená"
      },
      "purpose": {
        "label": "Používanie"
      },
      "startTime": {
        "label": "Čas platby"
      }
    }
  },
  "cs-CZ": {
    "payment": {
      "amount": {
        "label": "Částka vyplacená"
      },
      "purpose": {
        "label": "Používání"
      },
      "startTime": {
        "label": "Čas platby"
      }
    }
  },
  "tr-TR": {
    "payment": {
      "amount": {
        "label": "Ödenen miktar"
      },
      "purpose": {
        "label": "Kullanım"
      },
      "startTime": {
        "label": "Ödeme zamanı"
      }
    }
  },
  "hu-HU": {
    "payment": {
      "amount": {
        "label": "Kifizetett összeg"
      },
      "purpose": {
        "label": "Használat"
      },
      "startTime": {
        "label": "Fizetés időpontja"
      }
    }
  },
  "fr-BE": {
    "payment": {
      "amount": {
        "label": "Le montant payé"
      },
      "purpose": {
        "label": "Usage"
      },
      "startTime": {
        "label": "Temps de transfert"
      }
    }
  },
  "fr-LU": {
    "payment": {
      "amount": {
        "label": "Le montant payé"
      },
      "purpose": {
        "label": "Usage"
      },
      "startTime": {
        "label": "Temps de transfert"
      }
    }
  },
  "mt-MT": {
    "payment": {
      "amount": {
        "label": "Ammont imħallas"
      },
      "purpose": {
        "label": "Użu"
      },
      "startTime": {
        "label": "Ħin tat-trasferiment"
      }
    }
  },
  "sl-SI": {
    "payment": {
      "amount": {
        "label": "Plačani znesek"
      },
      "purpose": {
        "label": "Uporaba"
      },
      "startTime": {
        "label": "Čas plačila"
      }
    }
  },
  "el-CY": {
    "payment": {
      "amount": {
        "label": "Ποσό που καταβάλλεται"
      },
      "purpose": {
        "label": "Χρήση"
      },
      "startTime": {
        "label": "Ώρα πληρωμής"
      }
    }
  },
  "tr-CY": {
    "payment": {
      "amount": {
        "label": "Ödenen miktar"
      },
      "purpose": {
        "label": "Kullanım"
      },
      "startTime": {
        "label": "Ödeme zamanı"
      }
    }
  },
  "uk-UA": {
    "payment": {
      "amount": {},
      "purpose": {},
      "startTime": {}
    }
  },
  "zh-CN": {
    "payment": {
      "amount": {
        "label": "支付的金额"
      },
      "purpose": {
        "label": "用法"
      },
      "startTime": {
        "label": "付款时间"
      }
    }
  }
}
</i18n>
