<script setup>
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
</script>
<script>
  import DocumentComposer from "@dutypay/document-composer";

  export default {
    name: "MobileDownloads",
    props: {
      PDFDocumentContent: {type: Array, required: true},
      computedData: {type: Object, required: true}
    },
    data() {
      return {
        loadingCreatePDF: false,
        PDFDownloadLink: undefined,
        PDFFileName: ''
      };
    },
    methods: {
      PDFCreateLink() {
        if (this.loadingCreatePDF === true)
          return;
        this.loadingCreatePDF = true;
        let self = this;
        let document = new DocumentComposer();
        document.createNewDocument(this.PDFDocumentContent, {documentTitle: `${this.computedData.document_number}`, fileName: `${this.computedData.document_number}`});
        document.requestPDFDocument().then(function (response) {
          let file = new Blob([response.data], {type: 'application/pdf'});
          self.PDFFileName = document.getFileName;
          self.PDFDownloadLink = window.URL.createObjectURL(file);
          self.loadingCreatePDF = false;
        }).catch(function (error) {
          self.loadingCreatePDF = false;
          if (self.debug)
            this.$dp.log(error);
          self.$notify.error({
            title: self.$t('notification.asyncError.title'),
            message: self.$t('notification.asyncError.message')
          });
        });
      }
    }
  }
</script>

<template>
  <div>
    <DpExpand>
      <div v-show="PDFDownloadLink" class="dp-flex" style="padding: 0.5em 0;">
        <div class="flex-basis-1">
          <DpFileIcon file-extension="pdf" width="3.2rem"/>
        </div>
        <div class="flex-basis-1" style="padding-inline-start: 1.2em;">
          <a :href="PDFDownloadLink" :download="PDFFileName">
            <span style="display: block; font-size: 1.2em;">{{PDFFileName}}</span>
            {{t('openPDF.label')}}
          </a>
        </div>
      </div>
    </DpExpand>
    <DpExpand>
      <dp-button v-show="!PDFDownloadLink"
                 @click="PDFCreateLink"
                 purpose="primary"
                 class="mobile-button full-width overflow-hidden"
                 v-loading="loadingCreatePDF">{{t('createPDF.buttonLabel')}}</dp-button>
    </DpExpand>
  </div>
</template>

<style scoped lang="scss">
</style>

<i18n>
{
  "ar-AE": {
    "openPDF": {
      "label": "تحميل PDF"
    },
    "createPDF": {
      "buttonLabel": "تحميل PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "خطأ"
      }
    }
  },
  "ar-EG": {
    "openPDF": {},
    "createPDF": {},
    "notification": {
      "asyncError": {}
    }
  },
  "ar-SA": {
    "openPDF": {},
    "createPDF": {},
    "notification": {
      "asyncError": {}
    }
  },
  "de-DE": {
    "openPDF": {
      "label": "PDF herunterladen"
    },
    "createPDF": {
      "buttonLabel": "PDF herunterladen"
    },
    "notification": {
      "asyncError": {
        "message": "taxhub.pages.Reports.ReportDetails.MobileDownloads.notification.asyncError.message",
        "title": "Fehler"
      }
    }
  },
  "de-AT": {
    "openPDF": {
      "label": "PDF herunterladen"
    },
    "createPDF": {
      "buttonLabel": "PDF herunterladen"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Fehler"
      }
    }
  },
  "de-CH": {
    "openPDF": {
      "label": "PDF herunterladen"
    },
    "createPDF": {
      "buttonLabel": "PDF herunterladen"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Fehler"
      }
    }
  },
  "en-US": {
    "openPDF": {
      "label": "Download PDF"
    },
    "createPDF": {
      "buttonLabel": "Download PDF"
    },
    "notification": {
      "asyncError": {
        "message": "taxhub.pages.Reports.ReportDetails.MobileDownloads.notification.asyncError.message",
        "title": "Error"
      }
    }
  },
  "en-GB": {
    "openPDF": {
      "label": "Download PDF"
    },
    "createPDF": {
      "buttonLabel": "Download PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Error"
      }
    }
  },
  "it-IT": {
    "openPDF": {
      "label": "Scarica il PDF"
    },
    "createPDF": {
      "buttonLabel": "Scarica il PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Errore"
      }
    }
  },
  "fr-FR": {
    "openPDF": {
      "label": "Télécharger le PDF"
    },
    "createPDF": {
      "buttonLabel": "Télécharger le PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Erreur"
      }
    }
  },
  "es-ES": {
    "openPDF": {
      "label": "Descargar PDF"
    },
    "createPDF": {
      "buttonLabel": "Descargar PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Error"
      }
    }
  },
  "pl-PL": {
    "openPDF": {
      "label": "pobierz PDF"
    },
    "createPDF": {
      "buttonLabel": "pobierz PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Błąd"
      }
    }
  },
  "nl-NL": {
    "openPDF": {
      "label": "Download PDF"
    },
    "createPDF": {
      "buttonLabel": "Download PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Fout"
      }
    }
  },
  "bg-BG": {
    "openPDF": {
      "label": "Изтеглете PDF"
    },
    "createPDF": {
      "buttonLabel": "Изтеглете PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "грешка"
      }
    }
  },
  "bs-BA": {
    "openPDF": {
      "label": "Preuzmite PDF"
    },
    "createPDF": {
      "buttonLabel": "Preuzmite PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Greška"
      }
    }
  },
  "da-DK": {
    "openPDF": {
      "label": "Download PDF"
    },
    "createPDF": {
      "buttonLabel": "Download PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Fejl"
      }
    }
  },
  "et-EE": {
    "openPDF": {
      "label": "Laadige alla PDF"
    },
    "createPDF": {
      "buttonLabel": "Laadige alla PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Viga"
      }
    }
  },
  "fi-FI": {
    "openPDF": {
      "label": "Lataa PDF"
    },
    "createPDF": {
      "buttonLabel": "Lataa PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Virhe"
      }
    }
  },
  "el-GR": {
    "openPDF": {
      "label": "Λήψη PDF"
    },
    "createPDF": {
      "buttonLabel": "Λήψη PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Σφάλμα"
      }
    }
  },
  "he-IL": {
    "openPDF": {
      "label": "הורד PDF"
    },
    "createPDF": {
      "buttonLabel": "הורד PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "שְׁגִיאָה"
      }
    }
  },
  "ga-IE": {
    "openPDF": {
      "label": "Íoslódáil PDF"
    },
    "createPDF": {
      "buttonLabel": "Íoslódáil PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Earráid"
      }
    }
  },
  "ja-JP": {
    "openPDF": {
      "label": "PDFをダウンロード"
    },
    "createPDF": {
      "buttonLabel": "PDFをダウンロード"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "エラー"
      }
    }
  },
  "hr-HR": {
    "openPDF": {
      "label": "Preuzmite PDF"
    },
    "createPDF": {
      "buttonLabel": "Preuzmite PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Pogreška"
      }
    }
  },
  "lv-LV": {
    "openPDF": {
      "label": "Lejupielādējiet PDF"
    },
    "createPDF": {
      "buttonLabel": "Lejupielādējiet PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Kļūda"
      }
    }
  },
  "lt-LT": {
    "openPDF": {
      "label": "Atsisiųsti PDF"
    },
    "createPDF": {
      "buttonLabel": "Atsisiųsti PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Klaida"
      }
    }
  },
  "pt-PT": {
    "openPDF": {
      "label": "Baixar PDF"
    },
    "createPDF": {
      "buttonLabel": "Baixar PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Erro"
      }
    }
  },
  "ro-RO": {
    "openPDF": {
      "label": "Descarcă PDF"
    },
    "createPDF": {
      "buttonLabel": "Descarcă PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Eroare"
      }
    }
  },
  "ru-RU": {
    "openPDF": {
      "label": "Скачать PDF"
    },
    "createPDF": {
      "buttonLabel": "Скачать PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Ошибка"
      }
    }
  },
  "sv-SE": {
    "openPDF": {
      "label": "Ladda ner PDF"
    },
    "createPDF": {
      "buttonLabel": "Ladda ner PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Fel"
      }
    }
  },
  "sk-SK": {
    "openPDF": {
      "label": "Stiahnite si PDF"
    },
    "createPDF": {
      "buttonLabel": "Stiahnite si PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Chyba"
      }
    }
  },
  "cs-CZ": {
    "openPDF": {
      "label": "Stáhnout PDF"
    },
    "createPDF": {
      "buttonLabel": "Stáhnout PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Chyba"
      }
    }
  },
  "tr-TR": {
    "openPDF": {
      "label": "PDF İndir"
    },
    "createPDF": {
      "buttonLabel": "PDF İndir"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Hata"
      }
    }
  },
  "hu-HU": {
    "openPDF": {
      "label": "Töltse le a PDF fájlt"
    },
    "createPDF": {
      "buttonLabel": "Töltse le a PDF fájlt"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Hiba"
      }
    }
  },
  "fr-BE": {
    "openPDF": {
      "label": "Télécharger le PDF"
    },
    "createPDF": {
      "buttonLabel": "Télécharger le PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Erreur"
      }
    }
  },
  "fr-LU": {
    "openPDF": {
      "label": "Télécharger le PDF"
    },
    "createPDF": {
      "buttonLabel": "Télécharger le PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Erreur"
      }
    }
  },
  "mt-MT": {
    "openPDF": {
      "label": "Tniżżel PDF"
    },
    "createPDF": {
      "buttonLabel": "Tniżżel PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Żball"
      }
    }
  },
  "sl-SI": {
    "openPDF": {
      "label": "Prenesite PDF"
    },
    "createPDF": {
      "buttonLabel": "Prenesite PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Napaka"
      }
    }
  },
  "el-CY": {
    "openPDF": {
      "label": "Λήψη PDF"
    },
    "createPDF": {
      "buttonLabel": "Λήψη PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Σφάλμα"
      }
    }
  },
  "tr-CY": {
    "openPDF": {
      "label": "PDF İndir"
    },
    "createPDF": {
      "buttonLabel": "PDF İndir"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "Hata"
      }
    }
  },
  "uk-UA": {
    "openPDF": {},
    "createPDF": {},
    "notification": {
      "asyncError": {}
    }
  },
  "zh-CN": {
    "openPDF": {
      "label": "下载PDF"
    },
    "createPDF": {
      "buttonLabel": "下载PDF"
    },
    "notification": {
      "asyncError": {
        "message": "",
        "title": "错误"
      }
    }
  }
}
</i18n>
