export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقارير"])}
        }
      },
      "ar-EG": {
        "reports": {
          
        }
      },
      "ar-SA": {
        "reports": {
          
        }
      },
      "de-DE": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte"])}
        }
      },
      "de-AT": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte"])}
        }
      },
      "de-CH": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte"])}
        }
      },
      "en-US": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])}
        }
      },
      "en-GB": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reports"])}
        }
      },
      "it-IT": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporti"])}
        }
      },
      "fr-FR": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])}
        }
      },
      "es-ES": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informes"])}
        }
      },
      "pl-PL": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporty"])}
        }
      },
      "nl-NL": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporten"])}
        }
      },
      "bg-BG": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доклади"])}
        }
      },
      "bs-BA": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvještaji"])}
        }
      },
      "da-DK": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter"])}
        }
      },
      "et-EE": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruanded"])}
        }
      },
      "fi-FI": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportit"])}
        }
      },
      "el-GR": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορές"])}
        }
      },
      "he-IL": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["דיווחים"])}
        }
      },
      "ga-IE": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuarascálacha"])}
        }
      },
      "ja-JP": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート"])}
        }
      },
      "hr-HR": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvještaji"])}
        }
      },
      "lv-LV": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņojumi"])}
        }
      },
      "lt-LT": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitos"])}
        }
      },
      "pt-PT": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relatórios"])}
        }
      },
      "ro-RO": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapoarte"])}
        }
      },
      "ru-RU": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчеты"])}
        }
      },
      "sv-SE": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter"])}
        }
      },
      "sk-SK": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správy"])}
        }
      },
      "cs-CZ": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zprávy"])}
        }
      },
      "tr-TR": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlar"])}
        }
      },
      "hu-HU": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentések"])}
        }
      },
      "fr-BE": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])}
        }
      },
      "fr-LU": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapports"])}
        }
      },
      "mt-MT": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporti"])}
        }
      },
      "sl-SI": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poročila"])}
        }
      },
      "el-CY": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορές"])}
        }
      },
      "tr-CY": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlar"])}
        }
      },
      "uk-UA": {
        "reports": {
          
        }
      },
      "zh-CN": {
        "reports": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告书"])}
        }
      }
    }
  })
}
