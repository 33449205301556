<script setup>
import {computed} from 'vue'
import { useCompanyStore } from '@dutypay/store-modules'
const companyStore = useCompanyStore()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const showContent = computed(() => {
  return companyStore.hasCompanies && companyStore.hasSelectedCompany;
})
</script>
<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Reports",
  }
</script>

<template>
  <div v-if="showContent" class="reports-content">
    <h1>{{t('reports.label')}}</h1>
      <router-view />
  </div>
</template>

<style scoped lang="scss">
.reports-content {
  margin: 1rem 0;
}
</style>

<i18n>
{
  "ar-AE": {
    "reports": {
      "label": "التقارير"
    }
  },
  "ar-EG": {
    "reports": {}
  },
  "ar-SA": {
    "reports": {}
  },
  "de-DE": {
    "reports": {
      "label": "Berichte"
    }
  },
  "de-AT": {
    "reports": {
      "label": "Berichte"
    }
  },
  "de-CH": {
    "reports": {
      "label": "Berichte"
    }
  },
  "en-US": {
    "reports": {
      "label": "Reports"
    }
  },
  "en-GB": {
    "reports": {
      "label": "Reports"
    }
  },
  "it-IT": {
    "reports": {
      "label": "Rapporti"
    }
  },
  "fr-FR": {
    "reports": {
      "label": "Rapports"
    }
  },
  "es-ES": {
    "reports": {
      "label": "Informes"
    }
  },
  "pl-PL": {
    "reports": {
      "label": "Raporty"
    }
  },
  "nl-NL": {
    "reports": {
      "label": "Rapporten"
    }
  },
  "bg-BG": {
    "reports": {
      "label": "Доклади"
    }
  },
  "bs-BA": {
    "reports": {
      "label": "Izvještaji"
    }
  },
  "da-DK": {
    "reports": {
      "label": "Rapporter"
    }
  },
  "et-EE": {
    "reports": {
      "label": "Aruanded"
    }
  },
  "fi-FI": {
    "reports": {
      "label": "Raportit"
    }
  },
  "el-GR": {
    "reports": {
      "label": "Αναφορές"
    }
  },
  "he-IL": {
    "reports": {
      "label": "דיווחים"
    }
  },
  "ga-IE": {
    "reports": {
      "label": "Tuarascálacha"
    }
  },
  "ja-JP": {
    "reports": {
      "label": "レポート"
    }
  },
  "hr-HR": {
    "reports": {
      "label": "Izvještaji"
    }
  },
  "lv-LV": {
    "reports": {
      "label": "Ziņojumi"
    }
  },
  "lt-LT": {
    "reports": {
      "label": "Ataskaitos"
    }
  },
  "pt-PT": {
    "reports": {
      "label": "Relatórios"
    }
  },
  "ro-RO": {
    "reports": {
      "label": "Rapoarte"
    }
  },
  "ru-RU": {
    "reports": {
      "label": "Отчеты"
    }
  },
  "sv-SE": {
    "reports": {
      "label": "Rapporter"
    }
  },
  "sk-SK": {
    "reports": {
      "label": "Správy"
    }
  },
  "cs-CZ": {
    "reports": {
      "label": "Zprávy"
    }
  },
  "tr-TR": {
    "reports": {
      "label": "Raporlar"
    }
  },
  "hu-HU": {
    "reports": {
      "label": "Jelentések"
    }
  },
  "fr-BE": {
    "reports": {
      "label": "Rapports"
    }
  },
  "fr-LU": {
    "reports": {
      "label": "Rapports"
    }
  },
  "mt-MT": {
    "reports": {
      "label": "Rapporti"
    }
  },
  "sl-SI": {
    "reports": {
      "label": "Poročila"
    }
  },
  "el-CY": {
    "reports": {
      "label": "Αναφορές"
    }
  },
  "tr-CY": {
    "reports": {
      "label": "Raporlar"
    }
  },
  "uk-UA": {
    "reports": {}
  },
  "zh-CN": {
    "reports": {
      "label": "报告书"
    }
  }
}
</i18n>
