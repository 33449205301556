<script setup>
import StatusTagComplete from "./StatusTags/StatusTagComplete";
import StatusTagPending from "./StatusTags/StatusTagPending";
</script>
<script>
  export default {
    name: "StatusTagMobile",
    props: {
      status: {required: true}
    }
  }
</script>

<template>
  <span>
    <template v-if="status">
          <StatusTagComplete class="status-icon"/>
      </template>
      <template v-else>
          <StatusTagPending class="status-icon"/>
      </template>
    </span>
</template>

<style scoped lang="scss">
  .status-icon {
    width: 1.2em;
    height: 1.2em;
  }
</style>
