export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خطأ"])}
          }
        }
      },
      "ar-EG": {
        "openPDF": {
          
        },
        "createPDF": {
          
        },
        "notification": {
          "asyncError": {
            
          }
        }
      },
      "ar-SA": {
        "openPDF": {
          
        },
        "createPDF": {
          
        },
        "notification": {
          "asyncError": {
            
          }
        }
      },
      "de-DE": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxhub.pages.Reports.ReportDetails.MobileDownloads.notification.asyncError.message"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          }
        }
      },
      "de-AT": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          }
        }
      },
      "de-CH": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF herunterladen"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])}
          }
        }
      },
      "en-US": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taxhub.pages.Reports.ReportDetails.MobileDownloads.notification.asyncError.message"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          }
        }
      },
      "en-GB": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          }
        }
      },
      "it-IT": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarica il PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])}
          }
        }
      },
      "fr-FR": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          }
        }
      },
      "es-ES": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
          }
        }
      },
      "pl-PL": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pobierz PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pobierz PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Błąd"])}
          }
        }
      },
      "nl-NL": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout"])}
          }
        }
      },
      "bg-BG": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изтеглете PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["грешка"])}
          }
        }
      },
      "bs-BA": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greška"])}
          }
        }
      },
      "da-DK": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fejl"])}
          }
        }
      },
      "et-EE": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadige alla PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadige alla PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viga"])}
          }
        }
      },
      "fi-FI": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lataa PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virhe"])}
          }
        }
      },
      "el-GR": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
          }
        }
      },
      "he-IL": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הורד PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הורד PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["שְׁגִיאָה"])}
          }
        }
      },
      "ga-IE": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Íoslódáil PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Íoslódáil PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earráid"])}
          }
        }
      },
      "ja-JP": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFをダウンロード"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDFをダウンロード"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー"])}
          }
        }
      },
      "hr-HR": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preuzmite PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogreška"])}
          }
        }
      },
      "lv-LV": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādējiet PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lejupielādējiet PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kļūda"])}
          }
        }
      },
      "lt-LT": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsisiųsti PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsisiųsti PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaida"])}
          }
        }
      },
      "pt-PT": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baixar PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erro"])}
          }
        }
      },
      "ro-RO": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarcă PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarcă PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eroare"])}
          }
        }
      },
      "ru-RU": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка"])}
          }
        }
      },
      "sv-SE": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ladda ner PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fel"])}
          }
        }
      },
      "sk-SK": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnite si PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnite si PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
          }
        }
      },
      "cs-CZ": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stáhnout PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])}
          }
        }
      },
      "tr-TR": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF İndir"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF İndir"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
          }
        }
      },
      "hu-HU": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse le a PDF fájlt"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse le a PDF fájlt"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiba"])}
          }
        }
      },
      "fr-BE": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          }
        }
      },
      "fr-LU": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
          }
        }
      },
      "mt-MT": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tniżżel PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tniżżel PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Żball"])}
          }
        }
      },
      "sl-SI": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesite PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenesite PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka"])}
          }
        }
      },
      "el-CY": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήψη PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σφάλμα"])}
          }
        }
      },
      "tr-CY": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF İndir"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF İndir"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hata"])}
          }
        }
      },
      "uk-UA": {
        "openPDF": {
          
        },
        "createPDF": {
          
        },
        "notification": {
          "asyncError": {
            
          }
        }
      },
      "zh-CN": {
        "openPDF": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载PDF"])}
        },
        "createPDF": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载PDF"])}
        },
        "notification": {
          "asyncError": {
            "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])}
          }
        }
      }
    }
  })
}
