export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت السداد"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة الدفع"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد فلتر"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تطبيق المرشحات"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرشحات واضحة"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["منقي"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الطلب"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد متعاقد"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ بدء الإبلاغ"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ انتهاء التقرير"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نوع التقرير"])}
          }
        }
      },
      "ar-EG": {
        "payment": {
          "startTime": {
            
          },
          "instrument": {
            
          }
        },
        "any": {
          
        },
        "applyFilters": {
          
        },
        "clearFilters": {
          
        },
        "filter": {
          
        },
        "order": {
          "date": {
            
          }
        },
        "contractCountry": {
          
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "report": {
          "type": {
            
          }
        }
      },
      "ar-SA": {
        "payment": {
          "startTime": {
            
          },
          "instrument": {
            
          }
        },
        "any": {
          
        },
        "applyFilters": {
          
        },
        "clearFilters": {
          
        },
        "filter": {
          
        },
        "order": {
          "date": {
            
          }
        },
        "contractCountry": {
          
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "report": {
          "type": {
            
          }
        }
      },
      "de-DE": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kein Filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter anwenden"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsland"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumbeginn"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumende"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtstyp"])}
          }
        }
      },
      "de-AT": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kein Filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter anwenden"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsland"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumbeginn"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumende"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtstyp"])}
          }
        }
      },
      "de-CH": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kein Filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter anwenden"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter löschen"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beauftragungsdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertragsland"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumbeginn"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraumende"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtstyp"])}
          }
        }
      },
      "en-US": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filters"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract country"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting start date"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting end date"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])}
          }
        }
      },
      "en-GB": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply filters"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear filters"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract country"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting start date"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting end date"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report type"])}
          }
        }
      },
      "it-IT": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di trasferimento"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strumento di pagamento"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["senza Filtro"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applica filtri"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella filtri"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data dell'ordine"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese contraente"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di inizio della segnalazione"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di fine del rapporto"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di Rapporto"])}
          }
        }
      },
      "fr-FR": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de filtre"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer des filtres"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays contractant"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début du rapport"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin du rapport"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rapport"])}
          }
        }
      },
      "es-ES": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de transferencia"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pago"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sin filtro"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar filtros"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtros claros"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de orden"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País contratante"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de inicio de informes"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de finalización del informe"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Informe"])}
          }
        }
      },
      "pl-PL": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez filtra"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zastosuj filtry"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wyczyść filtry"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data zatwierdzenia danych"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsługiwany kraj"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek okresu podlegającego zgłoszeniu"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec okresu podlegającego zgłoszeniu"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Raportu"])}
          }
        }
      },
      "nl-NL": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip van betaling"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalinstrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters toepassen"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duidelijke filters"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besteldatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verdragsluitende land"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum rapportage"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einddatum rapportage"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporttype"])}
          }
        }
      },
      "bg-BG": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време на плащане"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежно нареждане"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["без филтър"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прилагане на филтри"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изчистване на филтрите"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["филтър"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на поръчка"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договаряща се страна"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчитане на начална дата"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Крайна дата на отчитане"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип Oтчет"])}
          }
        }
      },
      "bs-BA": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez filtera"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primjena filtera"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jasni filtri"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narudžbe"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država ugovornica"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum početka izvještavanja"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajnji datum izvještavanja"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta Izvještaja"])}
          }
        }
      },
      "da-DK": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt for betaling"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinstrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["intet filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvend filtre"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ryd filtre"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillingsdato"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontraherende land"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporterings startdato"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporterings slutdato"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporttype"])}
          }
        }
      },
      "et-EE": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse aeg"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksevahend"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ilma filtrita"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakenda filtrid"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tühjendage filtrid"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreeri"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tellimuse kuupäev"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lepinguosaline riik"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande alguskuupäev"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande lõppkuupäev"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruande Tüüp"])}
          }
        }
      },
      "fi-FI": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuaika"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutapa"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ei filtteriä"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käytä suodattimia"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tyhjennä suodattimet"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suodattaa"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilauspäivämäärä"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sopimusmaa"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportoinnin alkamispäivä"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportoinnin lopetuspäivä"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporttityyppi"])}
          }
        }
      },
      "el-GR": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χωρίς φίλτρο"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμόστε φίλτρα"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καθαρά φίλτρα"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία παραγγελίας"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμβαλλόμενη χώρα"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης αναφοράς"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος Aναφοράς"])}
          }
        }
      },
      "he-IL": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["זמן התשלום"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכשיר תשלום"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ללא מסנן"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["החל פילטרים"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מסננים ברורים"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְסַנֵן"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך הזמנה"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מדינה מתקשרת"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מדווח על תאריך התחלה"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך סיום מדווח"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוג דוח"])}
          }
        }
      },
      "ga-IE": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am na híocaíochta"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lonstraim íocaíochta"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gan Scagaire"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir scagairí i bhfeidhm"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["scagairí soiléire"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scagaire"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta ordaithe"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tír chonarthach"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta tosaigh tuairiscithe"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta deiridh tuairiscithe"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cineál Tuairisc"])}
          }
        }
      },
      "ja-JP": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い時期"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターなし"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターを適用する"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルターをクリア"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルタ"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文日"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["締約国"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート開始日"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポート終了日"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レポートタイプ"])}
          }
        }
      },
      "hr-HR": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bez filtera"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primjena filtera"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čisti filtri"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtar"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum narudžbe"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja ugovornica"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum početka izvještavanja"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajnji datum izvještavanja"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta Izvješća"])}
          }
        }
      },
      "lv-LV": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmaksas laiks"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksāšanas līdzeklis"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nav filtra"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietot filtrus"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notīrīt filtrus"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrēt"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasūtījuma datums"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Līgumslēdzēja valsts"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziņošanas sākuma datums"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata beigu datums"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata Veids"])}
          }
        }
      },
      "lt-LT": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikas"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo priemonė"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nėra filtro"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taikyti filtrus"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["išvalyti filtrus"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtras"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsakymo data"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Susitariančioji šalis"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitų teikimo pradžios data"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitos pabaigos data"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitos Tipas"])}
          }
        }
      },
      "pt-PT": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora do pagamento"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pagamento"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sem filtro"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar filtros"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limpar filtros"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data do pedido"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País contratante"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de início do relatório"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de término do relatório"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Relatório"])}
          }
        }
      },
      "ro-RO": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora de plată"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de plată"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fără filtru"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicați filtre"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtre clare"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtru"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data comandă"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țara contractantă"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data începerii raportării"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de încheiere a raportării"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip de Raport"])}
          }
        }
      },
      "ru-RU": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время оплаты"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный инструмент"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нет фильтра"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Применить фильтры"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["очистить фильтры"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата предоставления данных"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Договаривающаяся страна"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата начала периода отчётности"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания периода отчётности"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид отчёта"])}
          }
        }
      },
      "sv-SE": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningstid"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsinstrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inget filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Använd filter"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rensa filter"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrera"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderdatum"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avtalsland"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsstartdatum"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringens slutdatum"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporttyp"])}
          }
        }
      },
      "sk-SK": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobný nástroj"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["žiadny filter"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použite filtre"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vyčistiť filtre"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum objednávky"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmluvná krajina"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum začiatku vykazovania"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum ukončenia prehľadu"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Hlásenia"])}
          }
        }
      },
      "cs-CZ": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební nástroj"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["žádný filtr"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použít filtry"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vymazat filtry"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtr"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum objednávky"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smluvní země"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum zahájení hlášení"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum ukončení vykazování"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Zprávy"])}
          }
        }
      },
      "tr-TR": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtresiz"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleri uygula"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtreleri temizle"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme yapılan ülke"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama başlangıç tarihi"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama bitiş tarihi"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Türü"])}
          }
        }
      },
      "hu-HU": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés időpontja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés módja"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nincs szűrő"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrők alkalmazása"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["törölje a szűrőket"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szűrő"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendelés dátuma"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerződő ország"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés kezdete"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés befejezési dátuma"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentés Típusa"])}
          }
        }
      },
      "fr-BE": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de filtre"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer des filtres"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays contractant"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début du rapport"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin du rapport"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rapport"])}
          }
        }
      },
      "fr-LU": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de filtre"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer des filtres"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer les filtres"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays contractant"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début du rapport"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin du rapport"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de rapport"])}
          }
        }
      },
      "mt-MT": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ħin tat-trasferiment"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strument tal-ħlas"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l-ebda filtru"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applika filtru"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtri Ċari"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iffiltra"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data tal-ordni"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pajjiż Kontraenti"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidu tal-perjodu tar-rappurtar"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmiem tal-perjodu tar-rappurtar"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tip ta 'Rapport"])}
          }
        }
      },
      "sl-SI": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas plačila"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilni instrument"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brez filtra"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporabi filtre"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["čisti filtri"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum naročila"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država pogodbenica"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum začetka poročanja"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Končni datum poročanja"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrsta Poročila"])}
          }
        }
      },
      "el-CY": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["χωρίς φίλτρο"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Εφαρμόστε φίλτρα"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["καθαρά φίλτρα"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φίλτρο"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία παραγγελίας"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Συμβαλλόμενη χώρα"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία έναρξης αναφοράς"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία λήξης αναφοράς"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τύπος Aναφοράς"])}
          }
        }
      },
      "tr-CY": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtresiz"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtreleri uygula"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["filtreleri temizle"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtre"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sipariş tarihi"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sözleşme yapılan ülke"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama başlangıç tarihi"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama bitiş tarihi"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapor Türü"])}
          }
        }
      },
      "uk-UA": {
        "payment": {
          "startTime": {
            
          },
          "instrument": {
            
          }
        },
        "any": {
          
        },
        "applyFilters": {
          
        },
        "clearFilters": {
          
        },
        "filter": {
          
        },
        "order": {
          "date": {
            
          }
        },
        "contractCountry": {
          
        },
        "reportingPeriod": {
          "startDate": {
            
          },
          "endDate": {
            
          }
        },
        "report": {
          "type": {
            
          }
        }
      },
      "zh-CN": {
        "payment": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款时间"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])}
          }
        },
        "any": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有过滤器"])}
        },
        "applyFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套用筛选器"])}
        },
        "clearFilters": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清除筛选"])}
        },
        "filter": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤"])}
        },
        "order": {
          "date": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订购日期"])}
          }
        },
        "contractCountry": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缔约国"])}
        },
        "reportingPeriod": {
          "startDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告开始日期"])}
          },
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告结束日期"])}
          }
        },
        "report": {
          "type": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告类型"])}
          }
        }
      }
    }
  })
}
