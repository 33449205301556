export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إحباط"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير تفاصيل الدفع"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحرير تفاصيل الدفع"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت السداد"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])}
        }
      },
      "ar-EG": {
        "cancel": {
          
        },
        "payment": {
          "status": {
            "edit": {
              
            },
            "set": {
              
            }
          },
          "startTime": {
            
          }
        },
        "save": {
          
        }
      },
      "ar-SA": {
        "cancel": {
          
        },
        "payment": {
          "status": {
            "edit": {
              
            },
            "set": {
              
            }
          },
          "startTime": {
            
          }
        },
        "save": {
          
        }
      },
      "de-DE": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
        }
      },
      "de-AT": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
        }
      },
      "de-CH": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsdetails bearbeiten"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])}
        }
      },
      "en-US": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
        }
      },
      "en-GB": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit payment details"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])}
        }
      },
      "it-IT": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulla"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dettagli di pagamento"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i dettagli di pagamento"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di trasferimento"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])}
        }
      },
      "fr-FR": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
        }
      },
      "es-ES": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalles de pago"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalles de pago"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de transferencia"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
        }
      },
      "pl-PL": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuluj"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szczegóły płatności"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edytuj szczegóły płatności"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapisz"])}
        }
      },
      "nl-NL": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbreken"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsgegevens bewerken"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsgegevens bewerken"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip van betaling"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opslaan"])}
        }
      },
      "bg-BG": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прекратяване"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на данните за плащане"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактиране на данните за плащане"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време на плащане"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])}
        }
      },
      "bs-BA": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekini"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremite"])}
        }
      },
      "da-DK": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger betalingsoplysninger"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediger betalingsoplysninger"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt for betaling"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gem"])}
        }
      },
      "et-EE": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Katkesta"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutke makse üksikasju"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muutke makse üksikasju"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse aeg"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvesta"])}
        }
      },
      "fi-FI": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keskeyttää"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa maksutietoja"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muokkaa maksutietoja"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuaika"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tallenna"])}
        }
      },
      "el-GR": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποβάλλω"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])}
        }
      },
      "he-IL": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לְהַפִּיל"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערוך את פרטי התשלום"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ערוך את פרטי התשלום"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["זמן התשלום"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לשמור"])}
        }
      },
      "ga-IE": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ginmhilleadh"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir sonraí íocaíochta in eagar"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuir sonraí íocaíochta in eagar"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am na híocaíochta"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábháil"])}
        }
      },
      "ja-JP": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アボート"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いの詳細を編集"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払いの詳細を編集"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い時期"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存する"])}
        }
      },
      "hr-HR": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekid"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uređivanje podataka o plaćanju"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spremi"])}
        }
      },
      "lv-LV": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārtraukt"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājuma informāciju"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rediģēt maksājuma informāciju"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmaksas laiks"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saglabāt"])}
        }
      },
      "lt-LT": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutraukti"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti mokėjimo informaciją"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redaguoti mokėjimo informaciją"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikas"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Išsaugoti"])}
        }
      },
      "pt-PT": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abortar"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalhes do pagamento"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar detalhes do pagamento"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora do pagamento"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guardar"])}
        }
      },
      "ro-RO": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abandonați"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați detaliile de plată"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editați detaliile de plată"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora de plată"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salvare"])}
        }
      },
      "ru-RU": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прервать"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить платежные реквизиты"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить платежные реквизиты"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время оплаты"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])}
        }
      },
      "sv-SE": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avbryta"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera betalningsinformation"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redigera betalningsinformation"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningstid"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spara"])}
        }
      },
      "sk-SK": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abort"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte podrobnosti platby"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravte podrobnosti platby"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť"])}
        }
      },
      "cs-CZ": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Přerušit"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit platební údaje"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravit platební údaje"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložit"])}
        }
      },
      "tr-TR": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])}
        }
      },
      "hu-HU": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mégse"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési adatok szerkesztése"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési adatok szerkesztése"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés időpontja"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])}
        }
      },
      "fr-BE": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
        }
      },
      "fr-LU": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les détails du paiement"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])}
        }
      },
      "mt-MT": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ikkanċella"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editja d-dettalji tal-ħlas"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editja d-dettalji tal-ħlas"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ħin tat-trasferiment"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issejvja"])}
        }
      },
      "sl-SI": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prekini"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredite podatke o plačilu"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uredite podatke o plačilu"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas plačila"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrani"])}
        }
      },
      "el-CY": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Άκυρο"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Επεξεργασία στοιχείων πληρωμής"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αποθήκευση"])}
        }
      },
      "tr-CY": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İptal"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme ayrıntılarını düzenleme"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaydet"])}
        }
      },
      "uk-UA": {
        "cancel": {
          
        },
        "payment": {
          "status": {
            "edit": {
              
            },
            "set": {
              
            }
          },
          "startTime": {
            
          }
        },
        "save": {
          
        }
      },
      "zh-CN": {
        "cancel": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中止"])}
        },
        "payment": {
          "status": {
            "edit": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改付款明细"])}
            },
            "set": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改付款明细"])}
            }
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款时间"])}
          }
        },
        "save": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
        }
      }
    }
  })
}
