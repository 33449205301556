export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ المدفوع"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إستعمال"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت السداد"])}
          }
        }
      },
      "ar-EG": {
        "payment": {
          "amount": {
            
          },
          "purpose": {
            
          },
          "startTime": {
            
          }
        }
      },
      "ar-SA": {
        "payment": {
          "amount": {
            
          },
          "purpose": {
            
          },
          "startTime": {
            
          }
        }
      },
      "de-DE": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Betrag"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        }
      },
      "de-AT": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Betrag"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        }
      },
      "de-CH": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezahlter Betrag"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwendungszweck"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungszeitpunkt"])}
          }
        }
      },
      "en-US": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Paid"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          }
        }
      },
      "en-GB": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Paid"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer time"])}
          }
        }
      },
      "it-IT": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo pagato"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzo"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo di trasferimento"])}
          }
        }
      },
      "fr-FR": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant payé"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          }
        }
      },
      "es-ES": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad pagada"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de transferencia"])}
          }
        }
      },
      "pl-PL": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opłata zapłacona"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stosowanie"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data płatności"])}
          }
        }
      },
      "nl-NL": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald bedrag"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruik"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijdstip van betaling"])}
          }
        }
      },
      "bg-BG": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платено количество"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Употреба"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Време на плащане"])}
          }
        }
      },
      "bs-BA": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćen"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upotreba"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          }
        }
      },
      "da-DK": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt beløb"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anvendelse"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidspunkt for betaling"])}
          }
        }
      },
      "et-EE": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makstud summa"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasutamine"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse aeg"])}
          }
        }
      },
      "fi-FI": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksettu summa"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käyttö"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuaika"])}
          }
        }
      },
      "el-GR": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό που καταβάλλεται"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήση"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          }
        }
      },
      "he-IL": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום ששולם"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["נוֹהָג"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["זמן התשלום"])}
          }
        }
      },
      "ga-IE": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méid a d'íoc"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úsáid"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Am na híocaíochta"])}
          }
        }
      },
      "ja-JP": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["払込金額"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用法"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お支払い時期"])}
          }
        }
      },
      "hr-HR": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uplaćeni iznos"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upotreba"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijeme plaćanja"])}
          }
        }
      },
      "lv-LV": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samaksātā summa"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietošana"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmaksas laiks"])}
          }
        }
      },
      "lt-LT": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sumokėta suma"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naudojimas"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikas"])}
          }
        }
      },
      "pt-PT": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantia paga"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uso"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora do pagamento"])}
          }
        }
      },
      "ro-RO": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma plătită"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizare"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora de plată"])}
          }
        }
      },
      "ru-RU": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оплаченная сумма"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["использование"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время оплаты"])}
          }
        }
      },
      "sv-SE": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt belopp"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Användande"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningstid"])}
          }
        }
      },
      "sk-SK": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka vyplatená"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používanie"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          }
        }
      },
      "cs-CZ": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka vyplacená"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Používání"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas platby"])}
          }
        }
      },
      "tr-TR": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenen miktar"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          }
        }
      },
      "hu-HU": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kifizetett összeg"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Használat"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés időpontja"])}
          }
        }
      },
      "fr-BE": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant payé"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          }
        }
      },
      "fr-LU": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le montant payé"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temps de transfert"])}
          }
        }
      },
      "mt-MT": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammont imħallas"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Użu"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ħin tat-trasferiment"])}
          }
        }
      },
      "sl-SI": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačani znesek"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uporaba"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas plačila"])}
          }
        }
      },
      "el-CY": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό που καταβάλλεται"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χρήση"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ώρα πληρωμής"])}
          }
        }
      },
      "tr-CY": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödenen miktar"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kullanım"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme zamanı"])}
          }
        }
      },
      "uk-UA": {
        "payment": {
          "amount": {
            
          },
          "purpose": {
            
          },
          "startTime": {
            
          }
        }
      },
      "zh-CN": {
        "payment": {
          "amount": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付的金额"])}
          },
          "purpose": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用法"])}
          },
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款时间"])}
          }
        }
      }
    }
  })
}
