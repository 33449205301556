<script setup>
import { useI18n } from 'vue-i18n'
import {inject, ref, defineEmits, watch} from "vue"
import {useReportsParameterStore} from "../../../stores/reportsParameters";
import {get, clone} from 'lodash';
import {ElDropdown, ElDropdownItem, ElDropdownMenu} from 'element-plus';
import 'element-plus/es/components/dropdown-item/style/css';
import 'element-plus/es/components/dropdown-menu/style/css';
import 'element-plus/es/components/dropdown/style/css';

const { t } = useI18n()
const composables = inject('composables');
const { isMobile } = composables.useViewportSize();
const emit = defineEmits(['select']);
const reportsParameterStore = useReportsParameterStore();

const active = ref('');
watch(reportsParameterStore.queryParameters, () => {
  getActive();
})

function getActive() {
  active.value = get(reportsParameterStore.queryParameters, 'sortBy', '');
}
async function applySort(command) {
  active.value = clone(command);
  reportsParameterStore.pushQueryParameters({'sortBy': active.value || undefined});
  emit('select');
}

</script>
<script>
  export default {
    name: "SortBy"
  }
</script>

<template>
  <el-dropdown @command="applySort" trigger="click" :class="{'block': isMobile}">
    <dp-button purpose="secondary" :class="{'full-width mobile-button': isMobile}">
      <div class="dp-flex justify-content__center">
          <DpIcon icon="sort"/>{{t('sortBy.buttonLabel')}}
      </div>
    </dp-button>
    <template #dropdown>
      <el-dropdown-menu class="sort-by__dropdown">
        <el-dropdown-item command="paymentEndDueTime" :class="{'active': active === '' || active === 'paymentEndDueTime'}"><DpIcon v-if="active === '' || active === 'paymentEndDueTime'" icon="check"/>{{t('payment.endDueTime.label')}}</el-dropdown-item>
        <el-dropdown-item command="createdStartTime" :class="{'active': active === 'createdStartTime'}"><DpIcon v-if="active === 'createdStartTime'" icon="check"/>{{t('created.startTime.label')}}</el-dropdown-item>
        <el-dropdown-item command="reportingPeriodEndDate" :class="{'active': active === 'reportingPeriodEndDate'}"><DpIcon v-if="active === 'reportingPeriodEndDate'" icon="check"/>{{t('reportingPeriod.endDate.label')}}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<style lang="scss">
  .sort-by__dropdown .el-dropdown-menu__item:not(.active) {
    padding-inline-start: 2.5em;
  }
</style>

<i18n>
{
  "ar-AE": {
    "reportingPeriod": {
      "endDate": {
        "label": "الفترة المشمولة بالتقرير"
      }
    },
    "created": {
      "startTime": {
        "label": "تاريخ الإنشاء"
      }
    },
    "sortBy": {
      "buttonLabel": "رتب حسب"
    },
    "payment": {
      "endDueTime": {
        "label": "الموعد الاخير للدفع"
      }
    }
  },
  "ar-EG": {
    "reportingPeriod": {
      "endDate": {}
    },
    "created": {
      "startTime": {}
    },
    "sortBy": {},
    "payment": {
      "endDueTime": {}
    }
  },
  "ar-SA": {
    "reportingPeriod": {
      "endDate": {}
    },
    "created": {
      "startTime": {}
    },
    "sortBy": {},
    "payment": {
      "endDueTime": {}
    }
  },
  "de-DE": {
    "reportingPeriod": {
      "endDate": {
        "label": "Meldezeitraum"
      }
    },
    "created": {
      "startTime": {
        "label": "Erstelldatum"
      }
    },
    "sortBy": {
      "buttonLabel": "Sortieren nach"
    },
    "payment": {
      "endDueTime": {
        "label": "Zahlfrist"
      }
    }
  },
  "de-AT": {
    "reportingPeriod": {
      "endDate": {
        "label": "Meldezeitraum"
      }
    },
    "created": {
      "startTime": {
        "label": "Erstelldatum"
      }
    },
    "sortBy": {
      "buttonLabel": "Sortieren nach"
    },
    "payment": {
      "endDueTime": {
        "label": "Zahlfrist"
      }
    }
  },
  "de-CH": {
    "reportingPeriod": {
      "endDate": {
        "label": "Meldezeitraum"
      }
    },
    "created": {
      "startTime": {
        "label": "Erstelldatum"
      }
    },
    "sortBy": {
      "buttonLabel": "Sortieren nach"
    },
    "payment": {
      "endDueTime": {
        "label": "Zahlfrist"
      }
    }
  },
  "en-US": {
    "reportingPeriod": {
      "endDate": {
        "label": "Reporting period"
      }
    },
    "created": {
      "startTime": {
        "label": "Creation date"
      }
    },
    "sortBy": {
      "buttonLabel": "Sort by"
    },
    "payment": {
      "endDueTime": {
        "label": "Payment deadline"
      }
    }
  },
  "en-GB": {
    "reportingPeriod": {
      "endDate": {
        "label": "Reporting period"
      }
    },
    "created": {
      "startTime": {
        "label": "Creation date"
      }
    },
    "sortBy": {
      "buttonLabel": "Sort by"
    },
    "payment": {
      "endDueTime": {
        "label": "Payment deadline"
      }
    }
  },
  "it-IT": {
    "reportingPeriod": {
      "endDate": {
        "label": "Periodo di riferimento"
      }
    },
    "created": {
      "startTime": {
        "label": "Data di creazione"
      }
    },
    "sortBy": {
      "buttonLabel": "Ordina per"
    },
    "payment": {
      "endDueTime": {
        "label": "Scadenza di pagamento"
      }
    }
  },
  "fr-FR": {
    "reportingPeriod": {
      "endDate": {
        "label": "Période de rapport"
      }
    },
    "created": {
      "startTime": {
        "label": "Date de création"
      }
    },
    "sortBy": {
      "buttonLabel": "Trier par"
    },
    "payment": {
      "endDueTime": {
        "label": "Date limite de paiement"
      }
    }
  },
  "es-ES": {
    "reportingPeriod": {
      "endDate": {
        "label": "Período de información"
      }
    },
    "created": {
      "startTime": {
        "label": "Fecha de creación"
      }
    },
    "sortBy": {
      "buttonLabel": "Ordenar por"
    },
    "payment": {
      "endDueTime": {
        "label": "Tiempo límite de pago"
      }
    }
  },
  "pl-PL": {
    "reportingPeriod": {
      "endDate": {
        "label": "Okres sprawozdawczy"
      }
    },
    "created": {
      "startTime": {
        "label": "Data utworzenia"
      }
    },
    "sortBy": {
      "buttonLabel": "Sortuj według"
    },
    "payment": {
      "endDueTime": {
        "label": "Termin platnosci"
      }
    }
  },
  "nl-NL": {
    "reportingPeriod": {
      "endDate": {
        "label": "Verslagperiode"
      }
    },
    "created": {
      "startTime": {
        "label": "Aanmaakdatum"
      }
    },
    "sortBy": {
      "buttonLabel": "Sorteer op"
    },
    "payment": {
      "endDueTime": {
        "label": "Betalingsdeadline"
      }
    }
  },
  "bg-BG": {
    "reportingPeriod": {
      "endDate": {
        "label": "Отчетен период"
      }
    },
    "created": {
      "startTime": {
        "label": "Дата на създаване"
      }
    },
    "sortBy": {
      "buttonLabel": "Cортиране по"
    },
    "payment": {
      "endDueTime": {
        "label": "Краен срок за плащане"
      }
    }
  },
  "bs-BA": {
    "reportingPeriod": {
      "endDate": {
        "label": "Izveštajni period"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum stvaranja"
      }
    },
    "sortBy": {
      "buttonLabel": "Sortiraj po"
    },
    "payment": {
      "endDueTime": {
        "label": "Rok plaćanja"
      }
    }
  },
  "da-DK": {
    "reportingPeriod": {
      "endDate": {
        "label": "Rapporteringsperiode"
      }
    },
    "created": {
      "startTime": {
        "label": "Oprettelsesdato"
      }
    },
    "sortBy": {
      "buttonLabel": "Sorter efter"
    },
    "payment": {
      "endDueTime": {
        "label": "Betalingsfrist"
      }
    }
  },
  "et-EE": {
    "reportingPeriod": {
      "endDate": {
        "label": "Aruandeperiood"
      }
    },
    "created": {
      "startTime": {
        "label": "Loomise kuupäev"
      }
    },
    "sortBy": {
      "buttonLabel": "Sorteerima"
    },
    "payment": {
      "endDueTime": {
        "label": "Maksetähtaeg"
      }
    }
  },
  "fi-FI": {
    "reportingPeriod": {
      "endDate": {
        "label": "Raportointikausi"
      }
    },
    "created": {
      "startTime": {
        "label": "Luomispäivä"
      }
    },
    "sortBy": {
      "buttonLabel": "Järjestä"
    },
    "payment": {
      "endDueTime": {
        "label": "Maksun määräaika"
      }
    }
  },
  "el-GR": {
    "reportingPeriod": {
      "endDate": {
        "label": "Περίοδος αναφοράς"
      }
    },
    "created": {
      "startTime": {
        "label": "Ημερομηνία δημιουργίας"
      }
    },
    "sortBy": {
      "buttonLabel": "ταξινόμηση κατά"
    },
    "payment": {
      "endDueTime": {
        "label": "Προθεσμία πληρωμής"
      }
    }
  },
  "he-IL": {
    "reportingPeriod": {
      "endDate": {
        "label": "תקופת הדיווח"
      }
    },
    "created": {
      "startTime": {
        "label": "תאריך היווצרות"
      }
    },
    "sortBy": {
      "buttonLabel": "מיין לפי"
    },
    "payment": {
      "endDueTime": {
        "label": "מועד אחרון לתשלום"
      }
    }
  },
  "ga-IE": {
    "reportingPeriod": {
      "endDate": {
        "label": "Tréimhse tuairiscithe"
      }
    },
    "created": {
      "startTime": {
        "label": "Dáta cruthaithe"
      }
    },
    "sortBy": {
      "buttonLabel": "Sórtáil de réir"
    },
    "payment": {
      "endDueTime": {
        "label": "Spriocdháta íocaíochta"
      }
    }
  },
  "ja-JP": {
    "reportingPeriod": {
      "endDate": {
        "label": "報告期間"
      }
    },
    "created": {
      "startTime": {
        "label": "作成日"
      }
    },
    "sortBy": {
      "buttonLabel": "並び替え"
    },
    "payment": {
      "endDueTime": {
        "label": "支払期限"
      }
    }
  },
  "hr-HR": {
    "reportingPeriod": {
      "endDate": {
        "label": "Izvještajno razdoblje"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum stvaranja"
      }
    },
    "sortBy": {
      "buttonLabel": "Poredaj po"
    },
    "payment": {
      "endDueTime": {
        "label": "Rok plaćanja"
      }
    }
  },
  "lv-LV": {
    "reportingPeriod": {
      "endDate": {
        "label": "Pārskata periods"
      }
    },
    "created": {
      "startTime": {
        "label": "Izveidošanas datums"
      }
    },
    "sortBy": {
      "buttonLabel": "Kārtot pēc"
    },
    "payment": {
      "endDueTime": {
        "label": "Maksājuma termiņš"
      }
    }
  },
  "lt-LT": {
    "reportingPeriod": {
      "endDate": {
        "label": "Ataskaitinis laikotarpis"
      }
    },
    "created": {
      "startTime": {
        "label": "Sukūrimo data"
      }
    },
    "sortBy": {
      "buttonLabel": "Rūšiuoti pagal"
    },
    "payment": {
      "endDueTime": {
        "label": "Apmokėjimo terminas"
      }
    }
  },
  "pt-PT": {
    "reportingPeriod": {
      "endDate": {
        "label": "Período coberto pelo relatório"
      }
    },
    "created": {
      "startTime": {
        "label": "Data de criação"
      }
    },
    "sortBy": {
      "buttonLabel": "Ordenar por"
    },
    "payment": {
      "endDueTime": {
        "label": "Prazo de pagamento"
      }
    }
  },
  "ro-RO": {
    "reportingPeriod": {
      "endDate": {
        "label": "Perioadă de raportare"
      }
    },
    "created": {
      "startTime": {
        "label": "Data crearii"
      }
    },
    "sortBy": {
      "buttonLabel": "Filtrează după"
    },
    "payment": {
      "endDueTime": {
        "label": "Termen de plata"
      }
    }
  },
  "ru-RU": {
    "reportingPeriod": {
      "endDate": {
        "label": "Отчетный период"
      }
    },
    "created": {
      "startTime": {
        "label": "Дата создания"
      }
    },
    "sortBy": {
      "buttonLabel": "Сортировать по"
    },
    "payment": {
      "endDueTime": {
        "label": "Срок оплаты"
      }
    }
  },
  "sv-SE": {
    "reportingPeriod": {
      "endDate": {
        "label": "Rapporteringsperiod"
      }
    },
    "created": {
      "startTime": {
        "label": "Skapelsedagen"
      }
    },
    "sortBy": {
      "buttonLabel": "Sortera efter"
    },
    "payment": {
      "endDueTime": {
        "label": "Sista betalningsdagen"
      }
    }
  },
  "sk-SK": {
    "reportingPeriod": {
      "endDate": {
        "label": "Vykazované obdobie"
      }
    },
    "created": {
      "startTime": {
        "label": "Dátum vytvorenia"
      }
    },
    "sortBy": {
      "buttonLabel": "Triediť podľa"
    },
    "payment": {
      "endDueTime": {
        "label": "Termín platby"
      }
    }
  },
  "cs-CZ": {
    "reportingPeriod": {
      "endDate": {
        "label": "Doba ohlášení"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum vzniku"
      }
    },
    "sortBy": {
      "buttonLabel": "Seřazeno podle"
    },
    "payment": {
      "endDueTime": {
        "label": "Termín platby"
      }
    }
  },
  "tr-TR": {
    "reportingPeriod": {
      "endDate": {
        "label": "Raporlama dönemi"
      }
    },
    "created": {
      "startTime": {
        "label": "Oluşturulma tarihi"
      }
    },
    "sortBy": {
      "buttonLabel": "Göre sırala"
    },
    "payment": {
      "endDueTime": {
        "label": "Son ödeme tarihi"
      }
    }
  },
  "hu-HU": {
    "reportingPeriod": {
      "endDate": {
        "label": "Jelentési időszak"
      }
    },
    "created": {
      "startTime": {
        "label": "Létrehozás dátuma"
      }
    },
    "sortBy": {
      "buttonLabel": "Sorrend"
    },
    "payment": {
      "endDueTime": {
        "label": "Fizetési határidő"
      }
    }
  },
  "fr-BE": {
    "reportingPeriod": {
      "endDate": {
        "label": "Période de rapport"
      }
    },
    "created": {
      "startTime": {
        "label": "Date de création"
      }
    },
    "sortBy": {
      "buttonLabel": "Trier par"
    },
    "payment": {
      "endDueTime": {
        "label": "Date limite de paiement"
      }
    }
  },
  "fr-LU": {
    "reportingPeriod": {
      "endDate": {
        "label": "Période de rapport"
      }
    },
    "created": {
      "startTime": {
        "label": "Date de création"
      }
    },
    "sortBy": {
      "buttonLabel": "Trier par"
    },
    "payment": {
      "endDueTime": {
        "label": "Date limite de paiement"
      }
    }
  },
  "mt-MT": {
    "reportingPeriod": {
      "endDate": {
        "label": "Perjodu ta 'Rappurtar"
      }
    },
    "created": {
      "startTime": {
        "label": "Data tal-ħolqien"
      }
    },
    "sortBy": {
      "buttonLabel": "Irranġa skond"
    },
    "payment": {
      "endDueTime": {
        "label": "Skadenza għall-ħlas"
      }
    }
  },
  "sl-SI": {
    "reportingPeriod": {
      "endDate": {
        "label": "Obdobje poročanja"
      }
    },
    "created": {
      "startTime": {
        "label": "Datum nastanka"
      }
    },
    "sortBy": {
      "buttonLabel": "Razvrsti po"
    },
    "payment": {
      "endDueTime": {
        "label": "Rok plačila"
      }
    }
  },
  "el-CY": {
    "reportingPeriod": {
      "endDate": {
        "label": "Περίοδος αναφοράς"
      }
    },
    "created": {
      "startTime": {
        "label": "Ημερομηνία δημιουργίας"
      }
    },
    "sortBy": {
      "buttonLabel": "ταξινόμηση κατά"
    },
    "payment": {
      "endDueTime": {
        "label": "Προθεσμία πληρωμής"
      }
    }
  },
  "tr-CY": {
    "reportingPeriod": {
      "endDate": {
        "label": "Raporlama dönemi"
      }
    },
    "created": {
      "startTime": {
        "label": "Oluşturulma tarihi"
      }
    },
    "sortBy": {
      "buttonLabel": "Göre sırala"
    },
    "payment": {
      "endDueTime": {
        "label": "Son ödeme tarihi"
      }
    }
  },
  "uk-UA": {
    "reportingPeriod": {
      "endDate": {}
    },
    "created": {
      "startTime": {}
    },
    "sortBy": {},
    "payment": {
      "endDueTime": {}
    }
  },
  "zh-CN": {
    "reportingPeriod": {
      "endDate": {
        "label": "报告期"
      }
    },
    "created": {
      "startTime": {
        "label": "创建日期"
      }
    },
    "sortBy": {
      "buttonLabel": "排序方式"
    },
    "payment": {
      "endDueTime": {
        "label": "付款期限"
      }
    }
  }
}
</i18n>
