<script setup>
import {useI18n} from 'vue-i18n'

const {t} = useI18n()
const {locale} = useI18n({ useScope: 'global' })
import dayjs from 'dayjs';
const localeData = require('dayjs/plugin/localeData');
dayjs.extend(localeData);
const customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat);
import {interceptor} from '@dutypay/utilities';
import {to} from "await-to-js";
const emit = defineEmits(['update', 'delete'])
const envStore = useEnvStore();
import {useEnvStore} from '@dutypay/store-modules'
import {defineProps, defineEmits, ref, reactive, computed, onMounted, watch} from 'vue';

const props = defineProps({
  id: {type: String, required: true},
  payment_startTime: {default: null},
  cancelable: {type: Boolean, default: true}
});

const loading = ref(false);
const paymentTimeForm = reactive({
  form: {
    dateTime: null
  }
});

async function savePaymentTime() {
  loading.value = true;
  let err, res;
  [err, res] = await to(interceptor.patch(`${envStore.apiUrls.taxhub}/api/v0/reports/${props.id}/payment/start-time`, {
    payment: {
      startTime: dayjs(paymentTimeForm.form.dateTime, [dayjs.localeData().longDateFormat('L'), dayjs.localeData().longDateFormat('l')], dayjs.locale()).format('YYYY-MM-DD[T00:00:00]')
    }
  }));
  if (err) throw err;
  loading.value = false;
  emit('update', res.data.result);
  return res;
}

async function deletePaymentTime() {
  loading.value = true;
  let err, res;
  [err, res] = await to(interceptor.delete(`${envStore.apiUrls.taxhub}/api/v0/reports/${props.id}/payment/start-time`));
  if (err) throw err;
  loading.value = false;
  emit('delete');
  paymentTimeForm.form.dateTime = null;
  emit('update', res.data.result);
  return res;
}

function readPaymentTime() {
  if (paymentTimeNotEmpty.value) {
    paymentTimeForm.form.dateTime = dayjs(props.payment_startTime).format('L');
  }
}

const paymentTimeNotEmpty = computed(() => {
  if (props.payment_startTime === null)
    return false;
  return dayjs(props.payment_startTime).isValid();
});

onMounted(() => {
  readPaymentTime();
});
watch(locale, () => {
  readPaymentTime();
})
</script>
<script>
export default {
  name: "PaymentTimeForm"
}
</script>

<template>
  <div class="payment-time-form">
    <h4 v-if="paymentTimeNotEmpty">{{ t('payment.status.edit.label') }}</h4>
    <h4 v-else>{{ t('payment.status.set.label') }}</h4>
    <DpForm :reference="`paymentTimeForm_${id}`"
            :action="savePaymentTime"
            v-model="paymentTimeForm.form"
            v-loading="loading"
            label-position="top"
            :primary-button-label="t('save.buttonLabel')">
      <DpDatePickerCustom v-model="paymentTimeForm.form.dateTime" required prop="dateTime"
                              :label="t('payment.startTime.label')"></DpDatePickerCustom>
      <template #secondaryButton v-if="cancelable">
        <dp-button purpose="secondary" @click="emit('cancel')">{{ t('cancel.buttonLabel') }}</dp-button>
      </template>
    </DpForm>
    <DpFormDeletionConfirmation v-if="paymentTimeNotEmpty"
                                :action="deletePaymentTime"
                                :disableNotification="true"
                                :reference="`paymentTimeForm_${id}`"/>
  </div>
</template>

<style lang="scss">
</style>

<i18n>
{
  "ar-AE": {
    "cancel": {
      "buttonLabel": "إحباط"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "تحرير تفاصيل الدفع"
        },
        "set": {
          "label": "تحرير تفاصيل الدفع"
        }
      },
      "startTime": {
        "label": "وقت السداد"
      }
    },
    "save": {
      "buttonLabel": "حفظ"
    }
  },
  "ar-EG": {
    "cancel": {},
    "payment": {
      "status": {
        "edit": {},
        "set": {}
      },
      "startTime": {}
    },
    "save": {}
  },
  "ar-SA": {
    "cancel": {},
    "payment": {
      "status": {
        "edit": {},
        "set": {}
      },
      "startTime": {}
    },
    "save": {}
  },
  "de-DE": {
    "cancel": {
      "buttonLabel": "Abbrechen"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Zahlungsdetails bearbeiten"
        },
        "set": {
          "label": "Zahlungsdetails bearbeiten"
        }
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    },
    "save": {
      "buttonLabel": "Speichern"
    }
  },
  "de-AT": {
    "cancel": {
      "buttonLabel": "Abbrechen"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Zahlungsdetails bearbeiten"
        },
        "set": {
          "label": "Zahlungsdetails bearbeiten"
        }
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    },
    "save": {
      "buttonLabel": "Speichern"
    }
  },
  "de-CH": {
    "cancel": {
      "buttonLabel": "Abbrechen"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Zahlungsdetails bearbeiten"
        },
        "set": {
          "label": "Zahlungsdetails bearbeiten"
        }
      },
      "startTime": {
        "label": "Zahlungszeitpunkt"
      }
    },
    "save": {
      "buttonLabel": "Speichern"
    }
  },
  "en-US": {
    "cancel": {
      "buttonLabel": "Cancel"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Edit payment details"
        },
        "set": {
          "label": "Edit payment details"
        }
      },
      "startTime": {
        "label": "Transfer time"
      }
    },
    "save": {
      "buttonLabel": "Save"
    }
  },
  "en-GB": {
    "cancel": {
      "buttonLabel": "Cancel"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Edit payment details"
        },
        "set": {
          "label": "Edit payment details"
        }
      },
      "startTime": {
        "label": "Transfer time"
      }
    },
    "save": {
      "buttonLabel": "Save"
    }
  },
  "it-IT": {
    "cancel": {
      "buttonLabel": "Annulla"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Modifica i dettagli di pagamento"
        },
        "set": {
          "label": "Modifica i dettagli di pagamento"
        }
      },
      "startTime": {
        "label": "Tempo di trasferimento"
      }
    },
    "save": {
      "buttonLabel": "Salva"
    }
  },
  "fr-FR": {
    "cancel": {
      "buttonLabel": "Annuler"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Modifier les détails du paiement"
        },
        "set": {
          "label": "Modifier les détails du paiement"
        }
      },
      "startTime": {
        "label": "Temps de transfert"
      }
    },
    "save": {
      "buttonLabel": "Enregistrer"
    }
  },
  "es-ES": {
    "cancel": {
      "buttonLabel": "Cancelar"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Editar detalles de pago"
        },
        "set": {
          "label": "Editar detalles de pago"
        }
      },
      "startTime": {
        "label": "Tiempo de transferencia"
      }
    },
    "save": {
      "buttonLabel": "Guardar"
    }
  },
  "pl-PL": {
    "cancel": {
      "buttonLabel": "Anuluj"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Edytuj szczegóły płatności"
        },
        "set": {
          "label": "Edytuj szczegóły płatności"
        }
      },
      "startTime": {
        "label": "Data płatności"
      }
    },
    "save": {
      "buttonLabel": "Zapisz"
    }
  },
  "nl-NL": {
    "cancel": {
      "buttonLabel": "Afbreken"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Betalingsgegevens bewerken"
        },
        "set": {
          "label": "Betalingsgegevens bewerken"
        }
      },
      "startTime": {
        "label": "Tijdstip van betaling"
      }
    },
    "save": {
      "buttonLabel": "Opslaan"
    }
  },
  "bg-BG": {
    "cancel": {
      "buttonLabel": "Прекратяване"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Редактиране на данните за плащане"
        },
        "set": {
          "label": "Редактиране на данните за плащане"
        }
      },
      "startTime": {
        "label": "Време на плащане"
      }
    },
    "save": {
      "buttonLabel": "Запазване"
    }
  },
  "bs-BA": {
    "cancel": {
      "buttonLabel": "Prekini"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Uređivanje podataka o plaćanju"
        },
        "set": {
          "label": "Uređivanje podataka o plaćanju"
        }
      },
      "startTime": {
        "label": "Vrijeme plaćanja"
      }
    },
    "save": {
      "buttonLabel": "Spremite"
    }
  },
  "da-DK": {
    "cancel": {
      "buttonLabel": "Abort"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Rediger betalingsoplysninger"
        },
        "set": {
          "label": "Rediger betalingsoplysninger"
        }
      },
      "startTime": {
        "label": "Tidspunkt for betaling"
      }
    },
    "save": {
      "buttonLabel": "Gem"
    }
  },
  "et-EE": {
    "cancel": {
      "buttonLabel": "Katkesta"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Muutke makse üksikasju"
        },
        "set": {
          "label": "Muutke makse üksikasju"
        }
      },
      "startTime": {
        "label": "Makse aeg"
      }
    },
    "save": {
      "buttonLabel": "Salvesta"
    }
  },
  "fi-FI": {
    "cancel": {
      "buttonLabel": "Keskeyttää"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Muokkaa maksutietoja"
        },
        "set": {
          "label": "Muokkaa maksutietoja"
        }
      },
      "startTime": {
        "label": "Maksuaika"
      }
    },
    "save": {
      "buttonLabel": "Tallenna"
    }
  },
  "el-GR": {
    "cancel": {
      "buttonLabel": "Αποβάλλω"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Επεξεργασία στοιχείων πληρωμής"
        },
        "set": {
          "label": "Επεξεργασία στοιχείων πληρωμής"
        }
      },
      "startTime": {
        "label": "Ώρα πληρωμής"
      }
    },
    "save": {
      "buttonLabel": "Αποθήκευση"
    }
  },
  "he-IL": {
    "cancel": {
      "buttonLabel": "לְהַפִּיל"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "ערוך את פרטי התשלום"
        },
        "set": {
          "label": "ערוך את פרטי התשלום"
        }
      },
      "startTime": {
        "label": "זמן התשלום"
      }
    },
    "save": {
      "buttonLabel": "לשמור"
    }
  },
  "ga-IE": {
    "cancel": {
      "buttonLabel": "Ginmhilleadh"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Cuir sonraí íocaíochta in eagar"
        },
        "set": {
          "label": "Cuir sonraí íocaíochta in eagar"
        }
      },
      "startTime": {
        "label": "Am na híocaíochta"
      }
    },
    "save": {
      "buttonLabel": "Sábháil"
    }
  },
  "ja-JP": {
    "cancel": {
      "buttonLabel": "アボート"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "お支払いの詳細を編集"
        },
        "set": {
          "label": "お支払いの詳細を編集"
        }
      },
      "startTime": {
        "label": "お支払い時期"
      }
    },
    "save": {
      "buttonLabel": "保存する"
    }
  },
  "hr-HR": {
    "cancel": {
      "buttonLabel": "Prekid"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Uređivanje podataka o plaćanju"
        },
        "set": {
          "label": "Uređivanje podataka o plaćanju"
        }
      },
      "startTime": {
        "label": "Vrijeme plaćanja"
      }
    },
    "save": {
      "buttonLabel": "Spremi"
    }
  },
  "lv-LV": {
    "cancel": {
      "buttonLabel": "Pārtraukt"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Rediģēt maksājuma informāciju"
        },
        "set": {
          "label": "Rediģēt maksājuma informāciju"
        }
      },
      "startTime": {
        "label": "Apmaksas laiks"
      }
    },
    "save": {
      "buttonLabel": "Saglabāt"
    }
  },
  "lt-LT": {
    "cancel": {
      "buttonLabel": "Nutraukti"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Redaguoti mokėjimo informaciją"
        },
        "set": {
          "label": "Redaguoti mokėjimo informaciją"
        }
      },
      "startTime": {
        "label": "Mokėjimo laikas"
      }
    },
    "save": {
      "buttonLabel": "Išsaugoti"
    }
  },
  "pt-PT": {
    "cancel": {
      "buttonLabel": "Abortar"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Editar detalhes do pagamento"
        },
        "set": {
          "label": "Editar detalhes do pagamento"
        }
      },
      "startTime": {
        "label": "Hora do pagamento"
      }
    },
    "save": {
      "buttonLabel": "Guardar"
    }
  },
  "ro-RO": {
    "cancel": {
      "buttonLabel": "Abandonați"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Editați detaliile de plată"
        },
        "set": {
          "label": "Editați detaliile de plată"
        }
      },
      "startTime": {
        "label": "Ora de plată"
      }
    },
    "save": {
      "buttonLabel": "Salvare"
    }
  },
  "ru-RU": {
    "cancel": {
      "buttonLabel": "Прервать"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Изменить платежные реквизиты"
        },
        "set": {
          "label": "Изменить платежные реквизиты"
        }
      },
      "startTime": {
        "label": "Время оплаты"
      }
    },
    "save": {
      "buttonLabel": "Сохранить"
    }
  },
  "sv-SE": {
    "cancel": {
      "buttonLabel": "Avbryta"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Redigera betalningsinformation"
        },
        "set": {
          "label": "Redigera betalningsinformation"
        }
      },
      "startTime": {
        "label": "Betalningstid"
      }
    },
    "save": {
      "buttonLabel": "Spara"
    }
  },
  "sk-SK": {
    "cancel": {
      "buttonLabel": "Abort"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Upravte podrobnosti platby"
        },
        "set": {
          "label": "Upravte podrobnosti platby"
        }
      },
      "startTime": {
        "label": "Čas platby"
      }
    },
    "save": {
      "buttonLabel": "Uložiť"
    }
  },
  "cs-CZ": {
    "cancel": {
      "buttonLabel": "Přerušit"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Upravit platební údaje"
        },
        "set": {
          "label": "Upravit platební údaje"
        }
      },
      "startTime": {
        "label": "Čas platby"
      }
    },
    "save": {
      "buttonLabel": "Uložit"
    }
  },
  "tr-TR": {
    "cancel": {
      "buttonLabel": "İptal"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Ödeme ayrıntılarını düzenleme"
        },
        "set": {
          "label": "Ödeme ayrıntılarını düzenleme"
        }
      },
      "startTime": {
        "label": "Ödeme zamanı"
      }
    },
    "save": {
      "buttonLabel": "Kaydet"
    }
  },
  "hu-HU": {
    "cancel": {
      "buttonLabel": "Mégse"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "A fizetési adatok szerkesztése"
        },
        "set": {
          "label": "A fizetési adatok szerkesztése"
        }
      },
      "startTime": {
        "label": "Fizetés időpontja"
      }
    },
    "save": {
      "buttonLabel": "Mentés"
    }
  },
  "fr-BE": {
    "cancel": {
      "buttonLabel": "Annuler"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Modifier les détails du paiement"
        },
        "set": {
          "label": "Modifier les détails du paiement"
        }
      },
      "startTime": {
        "label": "Temps de transfert"
      }
    },
    "save": {
      "buttonLabel": "Enregistrer"
    }
  },
  "fr-LU": {
    "cancel": {
      "buttonLabel": "Annuler"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Modifier les détails du paiement"
        },
        "set": {
          "label": "Modifier les détails du paiement"
        }
      },
      "startTime": {
        "label": "Temps de transfert"
      }
    },
    "save": {
      "buttonLabel": "Enregistrer"
    }
  },
  "mt-MT": {
    "cancel": {
      "buttonLabel": "Ikkanċella"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Editja d-dettalji tal-ħlas"
        },
        "set": {
          "label": "Editja d-dettalji tal-ħlas"
        }
      },
      "startTime": {
        "label": "Ħin tat-trasferiment"
      }
    },
    "save": {
      "buttonLabel": "Issejvja"
    }
  },
  "sl-SI": {
    "cancel": {
      "buttonLabel": "Prekini"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Uredite podatke o plačilu"
        },
        "set": {
          "label": "Uredite podatke o plačilu"
        }
      },
      "startTime": {
        "label": "Čas plačila"
      }
    },
    "save": {
      "buttonLabel": "Shrani"
    }
  },
  "el-CY": {
    "cancel": {
      "buttonLabel": "Άκυρο"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Επεξεργασία στοιχείων πληρωμής"
        },
        "set": {
          "label": "Επεξεργασία στοιχείων πληρωμής"
        }
      },
      "startTime": {
        "label": "Ώρα πληρωμής"
      }
    },
    "save": {
      "buttonLabel": "Αποθήκευση"
    }
  },
  "tr-CY": {
    "cancel": {
      "buttonLabel": "İptal"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "Ödeme ayrıntılarını düzenleme"
        },
        "set": {
          "label": "Ödeme ayrıntılarını düzenleme"
        }
      },
      "startTime": {
        "label": "Ödeme zamanı"
      }
    },
    "save": {
      "buttonLabel": "Kaydet"
    }
  },
  "uk-UA": {
    "cancel": {},
    "payment": {
      "status": {
        "edit": {},
        "set": {}
      },
      "startTime": {}
    },
    "save": {}
  },
  "zh-CN": {
    "cancel": {
      "buttonLabel": "中止"
    },
    "payment": {
      "status": {
        "edit": {
          "label": "修改付款明细"
        },
        "set": {
          "label": "修改付款明细"
        }
      },
      "startTime": {
        "label": "付款时间"
      }
    },
    "save": {
      "buttonLabel": "保存"
    }
  }
}
</i18n>
