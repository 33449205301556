export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بداية فترة السداد"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وسيلة الدفع"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معهد المدفوع لأمره"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد مقر المعهد"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد مقر المعهد"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معهد"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشارة دفع"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نهاية فترة السداد"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبلغ الدفع"])}
        }
      },
      "ar-EG": {
        "payment": {
          "startDueTime": {
            
          },
          "instrument": {
            
          },
          "recipient": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "swiftcode": {
              
            },
            "iban": {
              
            }
          },
          "agent": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            }
          },
          "reference": {
            
          },
          "endDueTime": {
            
          }
        },
        "totalPaymentDue": {
          
        }
      },
      "ar-SA": {
        "payment": {
          "startDueTime": {
            
          },
          "instrument": {
            
          },
          "recipient": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "swiftcode": {
              
            },
            "iban": {
              
            }
          },
          "agent": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            }
          },
          "reference": {
            
          },
          "endDueTime": {
            
          }
        },
        "totalPaymentDue": {
          
        }
      },
      "de-DE": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn der Zahlfrist"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut des Zahlungsempfängers"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsreferenz"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        }
      },
      "de-AT": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn der Zahlfrist"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut des Zahlungsempfängers"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsreferenz"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        }
      },
      "de-CH": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginn der Zahlfrist"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsinstrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut des Zahlungsempfängers"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitzland des Instituts"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoinhaber"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsreferenz"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende der Zahlfrist"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlbetrag"])}
        }
      },
      "en-US": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning of the payment period"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payee's Institute"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment reference"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the payment period"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])}
        }
      },
      "en-GB": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginning of the payment period"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment instrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payee's Institute"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country of Seat of the Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institute"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account owner"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment reference"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End of the payment period"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment amount"])}
        }
      },
      "it-IT": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizio del periodo di pagamento"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strumento di pagamento"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istituto del beneficiario"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di sede dell'Istituto"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare dell'account"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paese di sede dell'Istituto"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istituto"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titolare dell'account"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenza di pagamento"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fine del periodo di pagamento"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo del pagamento"])}
        }
      },
      "fr-FR": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la période de paiement"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut du bénéficiaire"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de paiement"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        }
      },
      "es-ES": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio del período de pago"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pago"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto del beneficiario"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de sede del Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario de la cuenta"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de sede del Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propietario de la cuenta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia de pago"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin del período de pago"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto del pago"])}
        }
      },
      "pl-PL": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Początek okresu płatności"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metoda płatności"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instytut odbiorcy płatności"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj siedziby instytutu"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel konta"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj siedziby instytutu"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instytut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Właściciel konta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tytuł przelewu"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec terminu płatności"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wysokość należności"])}
        }
      },
      "nl-NL": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin van de betalingsperiode"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaalinstrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het instituut van de begunstigde"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land van zetel van het Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounteigenaar"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land van zetel van het Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounteigenaar"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingskenmerk"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einde van de betalingsperiode"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te betalen bedrag"])}
        }
      },
      "bg-BG": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало на периода на плащане"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежно нареждане"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институтът на бенефициента"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Държава на седалището на Института"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cобственик на акаунт"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Държава на седалището на Института"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институт"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cобственик на акаунт"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Справка за плащане"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок за плащане на ДДС"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сума за плащане"])}
        }
      },
      "bs-BA": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početak perioda plaćanja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut primatelja"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenca o uplati"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj perioda plaćanja"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćanja"])}
        }
      },
      "da-DK": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begyndelsen af betalingsperioden"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinstrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsmodtagerens institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut for sæde"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindehaver"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut for sæde"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoindehaver"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings reference"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsperiodens slutning"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsbeløb"])}
        }
      },
      "et-EE": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseperioodi algus"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksevahend"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saaja instituut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituudi asukohariik"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto omanik"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituudi asukohariik"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto omanik"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseviide"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makseperioodi lõpp"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Makse summa"])}
        }
      },
      "fi-FI": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuajan alku"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksutapa"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksunsaajan instituutti"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituutin kotimaa"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin omistaja"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituutin kotimaa"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituutti"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilin omistaja"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuviite"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksuajan päättyminen"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun määrä"])}
        }
      },
      "el-GR": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη της περιόδου πληρωμής"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο δικαιούχου πληρωμής"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά πληρωμής"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη της περιόδου πληρωμής"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό ΠΛΗΡΩΜΗΣ"])}
        }
      },
      "he-IL": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תחילת תקופת התשלום"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכשיר תשלום"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכון מקבל התשלום"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ארץ מושב המכון"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בעל החשבון"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ארץ מושב המכון"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מכון"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["בעל החשבון"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["הפניה לתשלום"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סוף תקופת התשלום"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["סכום לתשלום"])}
        }
      },
      "ga-IE": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tús na tréimhse íocaíochta"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lonstraim íocaíochta"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institiúid an íocaí"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tír Suíochán an Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úinéir an chuntais"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tír Suíochán an Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institiúid"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úinéir an chuntais"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagairt íocaíochta"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deireadh na tréimhse íocaíochta"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méid íocaíochta"])}
        }
      },
      "ja-JP": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い期間の開始"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い方法"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受取人の研究所"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所の座席の国"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの所有者"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所の座席の国"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの所有者"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払いリファレンス"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払い期間の終了"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払金額"])}
        }
      },
      "hr-HR": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početak razdoblja plaćanja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument plaćanja"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut primatelja"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zemlja sjedišta Instituta"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlasnik računa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenca o plaćanju"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kraj razdoblja plaćanja"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iznos plaćanja"])}
        }
      },
      "lv-LV": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma perioda sākums"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksāšanas līdzeklis"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma saņēmēja institūts"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institūta galvenā mītnes valsts"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta īpašnieks"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institūta galvenā mītnes valsts"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institūts"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konta īpašnieks"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma atsauce"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma termiņa beigas"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma summa"])}
        }
      },
      "lt-LT": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikotarpio pradžia"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo priemonė"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gavėjo institutas"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto buveinės šalis"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskyros savininkas"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto buveinės šalis"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutas"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paskyros savininkas"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo tvarka"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo laikotarpio pabaiga"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mokėjimo suma"])}
        }
      },
      "pt-PT": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Início do período de pagamento"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrumento de pagamento"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto do beneficiário"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de Sede do Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dono da conta"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País de Sede do Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituto"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dono da conta"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referência de pagamento"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim do período de pagamento"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valor do pagamento"])}
        }
      },
      "ro-RO": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Începutul perioadei de plată"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de plată"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutul beneficiarului"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țara de reședință a Institutului"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietarul contului"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Țara de reședință a Institutului"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proprietarul contului"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referință de plată"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sfârșitul perioadei de plată"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma de plată"])}
        }
      },
      "ru-RU": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начало платежного периода"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платежный инструмент"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институт получателя платежей"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна местонахождения института"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владелец счета"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Страна местонахождения института"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Институт"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["владелец счета"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка на платеж"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конец платежного периода"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма к оплате"])}
        }
      },
      "sv-SE": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Början på betalningsperioden"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsinstrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsmottagarens institut"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutets säte"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto ägare"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutets säte"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inleda"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto ägare"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsreferens"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsperiodens slut"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalningsbelopp"])}
        }
      },
      "sk-SK": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začiatok platobného obdobia"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobný nástroj"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inštitút príjemcu platby"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina sídla inštitútu"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník účtu"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina sídla inštitútu"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ústav"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník účtu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Označenie platby"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koniec platobného obdobia"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka na úhradu"])}
        }
      },
      "cs-CZ": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začátek platebního období"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platební nástroj"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut příjemce platby"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země sídla institutu"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majitel účtu"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Země sídla institutu"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ústav"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Majitel účtu"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na platbu"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec platebního období"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Částka k úhradě"])}
        }
      },
      "tr-TR": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme döneminin başlangıcı"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacaklı enstitüsü"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitü"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme referansı"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme süresinin sonu"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme miktarı"])}
        }
      },
      "hu-HU": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési időszak kezdete"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés módja"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedvezményezett intézmény"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az intézet székhelye"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatulajdonos"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az intézet székhelye"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intézet"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Számlatulajdonos"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési referencia"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetési időszak vége"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Végösszeg"])}
        }
      },
      "fr-BE": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la période de paiement"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut du bénéficiaire"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de paiement"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        }
      },
      "fr-LU": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début de la période de paiement"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instrument de paiement"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut du bénéficiaire"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays du siège de l'Institut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire du compte"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de paiement"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin de la période de paiement"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du paiement"])}
        }
      },
      "mt-MT": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bidu tal-perjodu tal-ħlas"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strument tal-ħlas"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L-istitut ta 'Payee"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pajjiż tas-Sede tal-Istitut"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sid tal-kont"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pajjiż tas-Sede tal-Istitut"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istitut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sid tal-kont"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenza tal-ħlas"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tmiem il-perjodu tal-ħlas"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ammont tal-ħlas"])}
        }
      },
      "sl-SI": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začetek plačilnega obdobja"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilni instrument"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inštitut prejemnika plačila"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država sedeža Inštituta"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastnik računa"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Država sedeža Inštituta"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inštitut"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lastnik računa"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sklic na plačilo"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konec plačilnega obdobja"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znesek plačila"])}
        }
      },
      "el-CY": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Έναρξη της περιόδου πληρωμής"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μέσο πληρωμής"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο δικαιούχου πληρωμής"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χώρα έδρας του Ινστιτούτου"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ινστιτούτο"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ιδιοκτήτης λογαριασμού"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναφορά πληρωμής"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λήξη της περιόδου πληρωμής"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ποσό ΠΛΗΡΩΜΗΣ"])}
        }
      },
      "tr-CY": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme döneminin başlangıcı"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme aracı"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alacaklı enstitüsü"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitünün Bulunduğu Ülke"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enstitü"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesap sahibi"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme referansı"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme süresinin sonu"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ödeme miktarı"])}
        }
      },
      "uk-UA": {
        "payment": {
          "startDueTime": {
            
          },
          "instrument": {
            
          },
          "recipient": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "swiftcode": {
              
            },
            "iban": {
              
            }
          },
          "agent": {
            "bank": {
              "country": {
                
              }
            },
            "account": {
              "owner": {
                
              }
            },
            "iban": {
              
            },
            "swiftcode": {
              
            }
          },
          "reference": {
            
          },
          "endDueTime": {
            
          }
        },
        "totalPaymentDue": {
          
        }
      },
      "zh-CN": {
        "payment": {
          "startDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款期开始"])}
          },
          "instrument": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])}
          },
          "recipient": {
            "bank": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款人学院"])},
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所所在地的国家"])}
              }
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户所有者"])}
              }
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工商银行"])}
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伊班"])}
            }
          },
          "agent": {
            "bank": {
              "country": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所所在地的国家"])}
              },
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["研究所"])}
            },
            "account": {
              "owner": {
                "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户所有者"])}
              }
            },
            "iban": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["伊班"])}
            },
            "swiftcode": {
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工商银行"])}
            }
          },
          "reference": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款凭据"])}
          },
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款期结束"])}
          }
        },
        "totalPaymentDue": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])}
        }
      }
    }
  })
}
