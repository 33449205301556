<script setup>
import {ElTag} from 'element-plus';
import 'element-plus/es/components/tag/style/css';

</script>
<script>
  export default {
    name: "FilterTag"
  }
</script>

<template>
  <el-tag class="filter-tag"
          :closable="true"
          :hit="true"
          :disable-transitions="true"
          size="large"
          v-on:close="$emit('close')">
    <slot></slot>
  </el-tag>
</template>

<style scoped lang="scss">
  .filter-tag {
    border-radius: 8px;
    margin-inline-start: 1em;
    margin-bottom: 1em;
  }
</style>
