export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بحث"])}
        }
      },
      "ar-EG": {
        "search": {
          
        }
      },
      "ar-SA": {
        "search": {
          
        }
      },
      "de-DE": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])}
        }
      },
      "de-AT": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])}
        }
      },
      "de-CH": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])}
        }
      },
      "en-US": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
        }
      },
      "en-GB": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])}
        }
      },
      "it-IT": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca"])}
        }
      },
      "fr-FR": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])}
        }
      },
      "es-ES": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])}
        }
      },
      "pl-PL": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szukaj"])}
        }
      },
      "nl-NL": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoeken"])}
        }
      },
      "bg-BG": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търсене"])}
        }
      },
      "bs-BA": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traži"])}
        }
      },
      "da-DK": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søg"])}
        }
      },
      "et-EE": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsing"])}
        }
      },
      "fi-FI": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hae"])}
        }
      },
      "el-GR": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])}
        }
      },
      "he-IL": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["לחפש"])}
        }
      },
      "ga-IE": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuardaigh"])}
        }
      },
      "ja-JP": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["探す"])}
        }
      },
      "hr-HR": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traži"])}
        }
      },
      "lv-LV": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meklēt"])}
        }
      },
      "lt-LT": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paieška"])}
        }
      },
      "pt-PT": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurar"])}
        }
      },
      "ro-RO": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Căutare"])}
        }
      },
      "ru-RU": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])}
        }
      },
      "sv-SE": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sök"])}
        }
      },
      "sk-SK": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie"])}
        }
      },
      "cs-CZ": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhledávání"])}
        }
      },
      "tr-TR": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama"])}
        }
      },
      "hu-HU": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresés"])}
        }
      },
      "fr-BE": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])}
        }
      },
      "fr-LU": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chercher"])}
        }
      },
      "mt-MT": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tfittxija"])}
        }
      },
      "sl-SI": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iskanje"])}
        }
      },
      "el-CY": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζήτηση"])}
        }
      },
      "tr-CY": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arama"])}
        }
      },
      "uk-UA": {
        "search": {
          
        }
      },
      "zh-CN": {
        "search": {
          "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
        }
      }
    }
  })
}
