export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "ar-AE": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الفترة المشمولة بالتقرير"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الإنشاء"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رتب حسب"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموعد الاخير للدفع"])}
          }
        }
      },
      "ar-EG": {
        "reportingPeriod": {
          "endDate": {
            
          }
        },
        "created": {
          "startTime": {
            
          }
        },
        "sortBy": {
          
        },
        "payment": {
          "endDueTime": {
            
          }
        }
      },
      "ar-SA": {
        "reportingPeriod": {
          "endDate": {
            
          }
        },
        "created": {
          "startTime": {
            
          }
        },
        "sortBy": {
          
        },
        "payment": {
          "endDueTime": {
            
          }
        }
      },
      "de-DE": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlfrist"])}
          }
        }
      },
      "de-AT": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlfrist"])}
          }
        }
      },
      "de-CH": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meldezeitraum"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortieren nach"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlfrist"])}
          }
        }
      },
      "en-US": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting period"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment deadline"])}
          }
        }
      },
      "en-GB": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting period"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creation date"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sort by"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment deadline"])}
          }
        }
      },
      "it-IT": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodo di riferimento"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di creazione"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordina per"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scadenza di pagamento"])}
          }
        }
      },
      "fr-FR": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de paiement"])}
          }
        }
      },
      "es-ES": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período de información"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de creación"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo límite de pago"])}
          }
        }
      },
      "pl-PL": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okres sprawozdawczy"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data utworzenia"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortuj według"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin platnosci"])}
          }
        }
      },
      "nl-NL": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verslagperiode"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aanmaakdatum"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteer op"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsdeadline"])}
          }
        }
      },
      "bg-BG": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчетен период"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на създаване"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cортиране по"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Краен срок за плащане"])}
          }
        }
      },
      "bs-BA": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveštajni period"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum stvaranja"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortiraj po"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok plaćanja"])}
          }
        }
      },
      "da-DK": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsperiode"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oprettelsesdato"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorter efter"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsfrist"])}
          }
        }
      },
      "et-EE": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aruandeperiood"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loomise kuupäev"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorteerima"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksetähtaeg"])}
          }
        }
      },
      "fi-FI": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raportointikausi"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luomispäivä"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Järjestä"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksun määräaika"])}
          }
        }
      },
      "el-GR": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος αναφοράς"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ταξινόμηση κατά"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προθεσμία πληρωμής"])}
          }
        }
      },
      "he-IL": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תקופת הדיווח"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["תאריך היווצרות"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מיין לפי"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["מועד אחרון לתשלום"])}
          }
        }
      },
      "ga-IE": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tréimhse tuairiscithe"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta cruthaithe"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sórtáil de réir"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spriocdháta íocaíochta"])}
          }
        }
      },
      "ja-JP": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報告期間"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["並び替え"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支払期限"])}
          }
        }
      },
      "hr-HR": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izvještajno razdoblje"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum stvaranja"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poredaj po"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok plaćanja"])}
          }
        }
      },
      "lv-LV": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pārskata periods"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izveidošanas datums"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kārtot pēc"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maksājuma termiņš"])}
          }
        }
      },
      "lt-LT": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ataskaitinis laikotarpis"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sukūrimo data"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rūšiuoti pagal"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apmokėjimo terminas"])}
          }
        }
      },
      "pt-PT": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Período coberto pelo relatório"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de criação"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar por"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prazo de pagamento"])}
          }
        }
      },
      "ro-RO": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perioadă de raportare"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data crearii"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrează după"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termen de plata"])}
          }
        }
      },
      "ru-RU": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отчетный период"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата создания"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сортировать по"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Срок оплаты"])}
          }
        }
      },
      "sv-SE": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporteringsperiod"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skapelsedagen"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortera efter"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sista betalningsdagen"])}
          }
        }
      },
      "sk-SK": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykazované obdobie"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum vytvorenia"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Triediť podľa"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termín platby"])}
          }
        }
      },
      "cs-CZ": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doba ohlášení"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum vzniku"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seřazeno podle"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termín platby"])}
          }
        }
      },
      "tr-TR": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama dönemi"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma tarihi"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göre sırala"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son ödeme tarihi"])}
          }
        }
      },
      "hu-HU": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelentési időszak"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Létrehozás dátuma"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorrend"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési határidő"])}
          }
        }
      },
      "fr-BE": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de paiement"])}
          }
        }
      },
      "fr-LU": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période de rapport"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date limite de paiement"])}
          }
        }
      },
      "mt-MT": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perjodu ta 'Rappurtar"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data tal-ħolqien"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irranġa skond"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skadenza għall-ħlas"])}
          }
        }
      },
      "sl-SI": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obdobje poročanja"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum nastanka"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razvrsti po"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok plačila"])}
          }
        }
      },
      "el-CY": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περίοδος αναφοράς"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ημερομηνία δημιουργίας"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ταξινόμηση κατά"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Προθεσμία πληρωμής"])}
          }
        }
      },
      "tr-CY": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raporlama dönemi"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oluşturulma tarihi"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Göre sırala"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Son ödeme tarihi"])}
          }
        }
      },
      "uk-UA": {
        "reportingPeriod": {
          "endDate": {
            
          }
        },
        "created": {
          "startTime": {
            
          }
        },
        "sortBy": {
          
        },
        "payment": {
          "endDueTime": {
            
          }
        }
      },
      "zh-CN": {
        "reportingPeriod": {
          "endDate": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报告期"])}
          }
        },
        "created": {
          "startTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])}
          }
        },
        "sortBy": {
          "buttonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排序方式"])}
        },
        "payment": {
          "endDueTime": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款期限"])}
          }
        }
      }
    }
  })
}
