<script>
  import get from 'lodash/get';
  import isEmpty from 'lodash/isEmpty';

  export default {
    name: "AdditionalInformation",
    props: {
      data: {type: Object}
    },
    computed: {
      hasData() {
        return !isEmpty(this.data);
      },
      hasTitle() {
        return !isEmpty(get(this.data, 'title', undefined));
      },
      hasSubTitle() {
        return !isEmpty(get(this.data, 'subTitle', undefined));
      },
      hasContent() {
        return !isEmpty(get(this.data, 'content', undefined));
      }
    }
  }
</script>

<template>
  <div v-if="hasData" class="text-block">
    <h3 v-if="hasTitle" class="title">{{data.title}}</h3>
    <p v-if="hasSubTitle" class="sub-title">{{data.subTitle}}</p>
    <div v-if="hasContent" class="text-block content" v-html="data.content"></div>
  </div>
</template>

<style scoped lang="scss">
  .title {
    padding: 0;
  }
  .sub-title {
    font-size: 1.2em;
    padding: 1em 0 0 0;
  }
  .content {
    padding: 1em 0 0 0;
  }
</style>

<i18n>
  {
  "en-US": {
  }
  }
</i18n>
