import {each, cloneDeep, isEmpty, get} from "lodash";

const localStorageKey = 'reportsParameters';

function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

import { defineStore } from 'pinia'

export const useReportsParameterStore = defineStore('reportsParameterStore', {
  state: () => {
    return {
      queryParameters: isJsonString(localStorage.getItem(localStorageKey)) ? JSON.parse(localStorage.getItem(localStorageKey)) || {} : {}
    }
  },
  getters: {
    hasSpCountryId: state => !isEmpty(get(state, 'queryParameters.spCountryId')),
    hasSpReportTypeId: state => !isEmpty(get(state, 'queryParameters.spReportTypeId')),
    hasSpPaymentInstrumentId: state => !isEmpty(get(state, 'queryParameters.spPaymentInstrumentId')),
    hasOrderDate: state => !isEmpty(get(state, 'queryParameters.orderDate')),
    hasPaymentStartTime: state => !isEmpty(get(state, 'queryParameters.paymentStartTime')),
    hasReportingPeriodStartDate: state => !isEmpty(get(state, 'queryParameters.reportingPeriodStartDate')),
    hasReportingPeriodEndDate: state => !isEmpty(get(state, 'queryParameters.reportingPeriodEndDate')),
    /**
     * Returns the aggregated has-Boolean of the filters.
     *
     * @returns {Boolean}
     */
    hasFilter() {
      return (
          this.hasSpCountryId
          || this.hasSpReportTypeId
          || this.hasSpPaymentInstrumentId
          || this.hasOrderDate
          || this.hasPaymentStartTime
          || this.hasReportingPeriodStartDate
          || this.hasReportingPeriodEndDate
      );
    }
  },
  actions: {
    pushQueryParameters(newParameters) {
      let queryParameters = this.queryParameters;
      each(newParameters, (value, key) => {
        if (value === undefined)
          delete queryParameters[key];
        else
          queryParameters[key] = value;
      });
      if(localStorage)
        localStorage.setItem(localStorageKey, JSON.stringify(queryParameters));
      this.queryParameters = cloneDeep(queryParameters)
    },
    resetQueryParameters() {
      if(localStorage)
        localStorage.setItem(localStorageKey, JSON.stringify({}));
      this.queryParameters = {}
    }
  }
})

